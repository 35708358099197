import React, { Fragment, useRef, useState } from "react";
import Slider from "react-slick";
import { NavLink } from "react-router-dom";

import Navbar from "../../component/navbar/Navbar";
import Footer from "../../component/footer/Footer";

import CardBlog from "../../component/card/CardBlog";
import CardWork from "../../component/card/CardWork";

const Index = () => {
  const tetsimonial = [];

  for (let index = 0; index < 12; index++) {
    tetsimonial.push(
      <div className="items">
        <div className="wrapper__card-tetsimonial bg__white">
          <p className="medium font__size--14 text__14-1024 lh-2 mb-3">
            Eget fusce massa quis lectus. Massa senectus mauris bibendum
            pulvinar volutpat dictum. Purus, non id aliquet nec odio arcu
            lacinia ac vel. Et orci ut diam mattis orci porta mi gravida
            bibendum.{" "}
          </p>
          <div className="profile">
            <img src="./../images/Ellipse 11.png" className="img" alt="" />
            <div className="ml-2">
              <h5 className="bold font__size--14 text__14-1024 mb-0">
                Rico Jonathan
              </h5>
              <p className="mb-0 medium font__size--12 text__12-1024 oapcity__5">
                Founder and CEO of DRONE
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1030,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const testimonailArrows = useRef();

  const gotoTestiNext = () => {
    testimonailArrows.current.slickNext();
  };

  const gotoTestiPrev = () => {
    testimonailArrows.current.slickPrev();
  };

  const [blogData, setBlogData] = useState([
    {
      img: "./../images/blog/IMAGE.png",
      title: "How is our process in working on the product design",
      desc: "Laoreet donec nibh orci est integer. Vitae faucibus consectetur id semper euismod sit. Cras maecenas nec pellentesque neque, eu. Adipiscing dignissim magna fusce feugiat enim, urna.",
      created: "Loius Nathan",
      job: "Sr. Brand Designer",
      profile: "./../images/PHOTO.png",
      link: "/blog/detail",
    },
    {
      img: "./../images/blog/IMAGE-1.png",
      title: "How is our process in working on the product design",
      desc: "Laoreet donec nibh orci est integer. Vitae faucibus consectetur id semper euismod sit. Cras maecenas nec pellentesque neque, eu. Adipiscing dignissim magna fusce feugiat enim, urna.",
      created: "Loius Nathan",
      job: "Sr. Brand Designer",
      profile: "./../images/PHOTO.png",
      link: "/blog/detail",
    },
    {
      img: "./../images/blog/IMAGE-2.png",
      title: "How is our process in working on the product design",
      desc: "Laoreet donec nibh orci est integer. Vitae faucibus consectetur id semper euismod sit. Cras maecenas nec pellentesque neque, eu. Adipiscing dignissim magna fusce feugiat enim, urna.",
      created: "Loius Nathan",
      job: "Sr. Brand Designer",
      profile: "./../images/PHOTO.png",
      link: "/blog/detail",
    },
  ]);

  const WorkList = [
    {
      img: "./../images/MOCKUP.png",
      title: "Real Estate Agency Web",
      desc: "Varius amet, integer tellus non eget viverra. Ultrices tellus donec gravida id sed senectus dolor cursus sed. ",
      class: "",
      link: "/work/detail",
    },
    {
      img: "./../images/MOCKUP (1).png",
      title: "Property Agent Website",
      desc: "Varius amet, integer tellus non eget viverra. Ultrices tellus donec gravida id sed senectus dolor cursus sed. ",
      class: "",
      link: "/work/detail",
    },
    {
      img: "./../images/MOCKUP (2).png",
      title: "Movie Apps Mobile",
      desc: "Varius amet, integer tellus non eget viverra. Ultrices tellus donec gravida id sed senectus dolor cursus sed. ",
      class: "",
      link: "/work/detail",
    },
    {
      img: "./../images/MOCKUP (3).png",
      title: "Finance App Mobile",
      desc: "Varius amet, integer tellus non eget viverra. Ultrices tellus donec gravida id sed senectus dolor cursus sed. ",
      class: "",
      link: "/work/detail",
    },
  ];
  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="position-relative bg__oldgreen">
          <Navbar />
          <div className="pt__100">
            <section className="section__head position-relative">
              <img src="./../images/PATTERN.png" className="path__1" alt="" />
              <div className="container position-relative z-2">
                <div className="text-center">
                  <h1 className="bold font__size--55 text__50-1024 text__50-sm text__50-xs color__white mb-0">
                    We help you create amazing{" "}
                    <br className="d-none d-md-block" /> products for your
                    business
                  </h1>
                  <p className="medium font__size--16 text__16-1024 text__16-md color__white opacity__5 my-4 lh-2">
                    We've over a decade of experienced mind working with us which help
                    your business to grow. <br className="d-none d-md-block" />{" "}
                    We don't just deliver products. We give your customers <br className="d-none d-md-block" />
                     a Digital experiences.{" "}
                  </p>

                  <div className="d-flex justify-content-center align-items-center">
                    <NavLink
                      to="/contact"
                      className="bold font__size--14 text__14-1024 btn btn__green color__oldgreen shadow btn__original"
                    >
                      Contact Us
                    </NavLink>
                    <NavLink
                      to="/about"
                      className="bold font__size--14 text__14-1024 btn btn__outlined--green color__green shadow btn__original no__opacity shadow ml-3 h__oldgreen"
                    >
                      About Us
                    </NavLink>
                  </div>
                </div>
              </div>
            </section>

            <section>
              <div className="container"></div>
            </section>
          </div>
        </div>
        <section>
          <div className="container">
            <div className="wrapper__video-play position-relative mb-5">
              <img src="./../images/IMAGE.png" className="preview" alt="" />
              <img
                src="./../images/BTN - PLAY.png"
                className="play pointer"
                alt=""
              />
            </div>

            <div className="wrapper__media-partner d-flex flex-wrap flex-md-nowrap justify-content-center align-items-center">
              <img src="./../images/Company logo.png" alt="" />
              <img src="./../images/Company logo (1).png" alt="" />
              <img src="./../images/Company logo (2).png" alt="" />
              <img src="./../images/Company logo (3).png" alt="" />
              <img src="./../images/Company logo (4).png" alt="" />
            </div>
          </div>
        </section>

        <section className="">
          <div className="row">
            <div className="col-md-5 bg__gray-2 p__4">
              <p className="bold font__size--18 text__18-1024 color__oldgreen ls__3 text-uppercase">
                ABOUT US
              </p>
              <h5 className="bold font__size--45 text__45-1024 text__45-md mb-5">
                Let's get to <br className="d-none d-md-block" /> know us more
              </h5>
              <img src="./../images/sasa.png" alt="" />
            </div>
            <div className="col-md-7 bg__oldgreen p__4">
              <p className="medium font__size--16 text__16-1024 text__16-md color__white lh-2 mb-4">
                We're not just another Web development company. We have over a decade of experience. 
                Experience in user perspective. Experience in proficiency. Experience in industries so diverse. 
                Travel, e-commerce, automobile, insurance, lifestyle, education. 
                You name an industry and chances are we’ve worked on making it better. 
                But we're not just an offshore Software development company, we are partners who support you with
                 everything backstage while preparing the spotlight on the frontstage.{" "}
              </p>
              <p className="medium font__size--16 text__16-1024 text__16-md color__white lh-2 mb-0">
              We have what it takes to offer you the best IT, Web, and Software Solutions. 
              Okay, there’s no denying that we’re a B2B web development agency, but with a purpose–to help
               you shine brighter.
              </p>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="text-center mb-5">
              <p className="bold font__size--18 text__18-1024 color__oldgreen ls__3 text-uppercase">
                OUR PROJECTS
              </p>
              <h5 className="bold font__size--45 text__45-1024 text__45-md mb-5">
                The services we provide <br /> for you
              </h5>
            </div>

            <div className="row">
              <div className="col-sm-6 col-lg-3 mb-4 mb-sm-0">
                <div className="wrapper__card-service text-center mt-sm-5">
                  <div className="icon">
                    <img src="./../images/asaa.png" alt="" />
                  </div>
                  <h5 className="bold font__size--20 text__20-1024 text__20-md mt-4 mb-5">
                    UX Research
                  </h5>
                  <NavLink
                    to="/contact"
                    className="color__black d-inline-block"
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="bold font__size--14 text__14-1024">
                        Learn More
                      </span>
                      <img
                        src="./../images/Frame (9).png"
                        className="ml-2"
                        alt=""
                      />
                    </div>
                  </NavLink>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3 mb-4 mb-sm-0">
                <div className="wrapper__card-service text-center">
                  <div className="icon">
                    <img src="./../images/ghghfg.png" alt="" />
                  </div>
                  <h5 className="bold font__size--20 text__20-1024 text__20-md mt-4 mb-5">
                    UI/UX Design
                  </h5>
                  <NavLink
                    to="/contact"
                    className="color__black d-inline-block"
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="bold font__size--14 text__14-1024">
                        Learn More
                      </span>
                      <img
                        src="./../images/Frame (9).png"
                        className="ml-2"
                        alt=""
                      />
                    </div>
                  </NavLink>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3 mb-4 mb-sm-0">
                <div className="wrapper__card-service text-center mt-sm-5">
                  <div className="icon">
                    <img src="./../images/tretert.png" alt="" />
                  </div>
                  <h5 className="bold font__size--20 text__20-1024 text__20-md mt-4 mb-5">
                    Digital Marketing
                  </h5>
                  <NavLink
                    to="/contact"
                    className="color__black d-inline-block"
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="bold font__size--14 text__14-1024">
                        Learn More
                      </span>
                      <img
                        src="./../images/Frame (9).png"
                        className="ml-2"
                        alt=""
                      />
                    </div>
                  </NavLink>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="wrapper__card-service text-center">
                  <div className="icon">
                    <img src="./../images/bvcbcv.png" alt="" />
                  </div>
                  <h5 className="bold font__size--20 text__20-1024 text__20-md mt-4 mb-5">
                    App/Web Development
                  </h5>
                  <NavLink
                    to="/contact"
                    className="color__black d-inline-block"
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="bold font__size--14 text__14-1024">
                        Learn More
                      </span>
                      <img
                        src="./../images/Frame (9).png"
                        className="ml-2"
                        alt=""
                      />
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="text-center mb-5">
              <p className="bold font__size--18 text__18-1024 color__oldgreen ls__3 text-uppercase">
                OUR SERVICES
              </p>
              <h5 className="bold font__size--45 text__45-1024 text__45-md mb-5">
                Featured projects we <br /> have completed
              </h5>
            </div>

            <div className="row justify-content-center">
              <div className="col-lg-10 col-xl-8">
                <div className="row justify-content-between">
                  {WorkList.map((obj, i) => {
                    return (
                      <div className="col-md-6 col-lg-5 mb-4 mb-md-0">
                        <div className={i % 2 != 0 ? "mt__4 sm-0" : ""}>
                          <CardWork data={obj} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="text-center mt__5">
              <NavLink
                to="/work"
                className="bold font__size--14 text__14-1024 btn btn__outlined--oldgreen color__oldgreen shadow btn__original no__opacity shadow h__green"
              >
                See More Project
              </NavLink>
            </div>
          </div>
        </section>

        <section className="bg__gray-2">
          <div className="container">
            <div className="position-relative mb-5">
              <img src="./../images/LINE.png" className="path__2" alt="" />
              <div className="row justify-content-between text-center text-md-left">
                <div className="col-md-5 my-lg-auto mb-4">
                  <p className="bold font__size--18 text__18-1024 color__oldgreen ls__3 text-uppercase">
                    TESTIMONIAL
                  </p>
                  <h5 className="bold font__size--45 text__45-1024 text__45-md">
                    What do they say <br /> about us?
                  </h5>
                </div>
                <div className="col-md-3 my-auto">
                  <div className="wrapper__arrows-testionial d-flex justify-content-lg-end justify-content-center align-items-center">
                    <div
                      onClick={() => gotoTestiNext()}
                      className="pointer left mr-3"
                    >
                      <svg
                        width="41"
                        height="41"
                        viewBox="0 0 41 41"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22.5105 29.559L12.9989 20.0473L22.5105 10.5356L24.8672 12.8923L17.7122 20.0473L24.8672 27.2023L22.5105 29.559Z"
                          fill="#05595B"
                        />
                      </svg>
                    </div>
                    <div
                      onClick={() => gotoTestiPrev()}
                      className="pointer right"
                    >
                      <svg
                        width="41"
                        height="41"
                        viewBox="0 0 41 41"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.2004 29.559L27.7121 20.0473L18.2004 10.5356L15.8438 12.8923L22.9987 20.0473L15.8438 27.2023L18.2004 29.559Z"
                          fill="#05595B"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Slider
              {...settings}
              ref={testimonailArrows}
              className="wrapper__slider-testimonial"
            >
              {tetsimonial}
            </Slider>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="text-center mb-5">
              <p className="bold font__size--18 text__18-1024 color__oldgreen ls__3 text-uppercase">
                BLOG
              </p>
              <h5 className="bold font__size--45 text__45-1024 text__45-md">
                We love to share knowledge
              </h5>
            </div>

            <div className="row justify-content-center">
              {blogData.map((obj) => {
                return (
                  <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
                    <CardBlog data={obj} />
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </Fragment>
  );
};

export default Index;
