import React, { useState, Fragment } from "react";
import { NavLink } from "react-router-dom";
const Navbar = (props) => {
  const [menu, setMenu] = useState(false);
  return (
    <Fragment>
      <nav
        className={
          "navbar__head fixed " +
          (props.type == "light" ? "light bg__white" : "bg__oldgreen")
        }
      >
        <div className="position-relative wrap">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between">
              <NavLink to="/" className="logo">
                <img
                  src={
                    props.type == "light"
                      ? "./../images/sdadsa.png"
                      : "./../images/LOGO (3).png"
                  }
                  alt=""
                  height={40}
                />
              </NavLink>
              <div
                className={
                  "memu__icon " + (props.type == "light" ? "light" : "")
                }
              >
                <div
                  onClick={() => setMenu(!menu)}
                  className={
                    "icon position-relative pointer " + (menu ? "active" : "")
                  }
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div className={"wrapper__menu-navbar " + (menu ? "active" : "")}>
        <div className="position-relative">
          <div className="bg" onClick={() => setMenu(!menu)}></div>
          <div className="menu__navbar position-relative">
            <div className="row">
              <div className="col-3 col-sm-2 text-center">
                <img
                  src="./../images/LOGO (3).png"
                  className="logo__menu"
                  alt=""
                  height={40}
                />
                <div className="line__height my-4"></div>
                <ul className="list__menu-sosmed">
                  <li>
                    <a href="#!">
                      <img src="./../images/sdad.png" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#!">
                      <img src="./../images/sdasd.png" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#!">
                      <img src="./../images/asdsad.png" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#!">
                      <img src="./../images/Frame (8).png" alt="" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-8 col-sm-5 col-lg-4">
                <ul className="list__menu-head">
                  <li>
                    <NavLink
                      exact
                      to="/"
                      className="bold font__size--55 text__50-1024 text__50-sm text__50-xs color__white"
                    >
                      HOME
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/about"
                      className="bold font__size--55 text__50-1024 text__50-sm text__50-xs color__white"
                    >
                      ABOUT
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/work"
                      className="bold font__size--55 text__50-1024 text__50-sm text__50-xs color__white"
                    >
                      WORK
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/blog"
                      className="bold font__size--55 text__50-1024 text__50-sm text__50-xs color__white"
                    >
                      BLOG
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/team" className="bold font__size--55 text__50-1024 text__50-sm text__50-xs color__white">
                      TEAM
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/career"
                      className="bold font__size--55 text__50-1024 text__50-sm text__50-xs color__white"
                    >
                      CAREER
                    </NavLink>
                  </li>
                </ul>

                <ul className="list__media-menu d-sm-none mt-4 mt-sm-0">
                  <li>
                    <div className="d-flex align-items-center">
                      <img src="./../images/Frame (5).png" alt="" />
                      <span className="semi-bold color__white font__size--14 text__14-1024 ml-2">
                        cryptoxy.in
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex align-items-center">
                      <img src="./../images/Frame (6).png" alt="" />
                      <span className="semi-bold color__white font__size--14 text__14-1024 ml-2">
                        hello@cryptoxy.in
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex align-items-center">
                      <img src="./../images/Frame (7).png" alt="" />
                      <span className="semi-bold color__white font__size--14 text__14-1024 ml-2">
                        +91 979 939 9555
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-sm-3 d-none d-sm-block">
                <ul className="list__media-menu">
                  <li>
                    <div className="d-flex align-items-center">
                      <img src="./../images/Frame (5).png" alt="" />
                      <span className="semi-bold color__white font__size--14 text__14-1024 ml-2">
                        cryptoxy.in
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex align-items-center">
                      <img src="./../images/Frame (6).png" alt="" />
                      <span className="semi-bold color__white font__size--14 text__14-1024 ml-2">
                        hello@cryptoxy.in
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex align-items-center">
                      <img src="./../images/Frame (7).png" alt="" />
                      <span className="semi-bold color__white font__size--14 text__14-1024 ml-2">
                        +91 979 939 9555
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Navbar;
