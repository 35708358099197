import React, { Fragment, useRef, useState } from "react";

import Navbar from "../../component/navbar/Navbar";
import Footer from "../../component/footer/Footer";
import OpenJob from "../../component/card/OpenJob";

const DetailCareer = () => {
  const openWork = [
    {
      position: "Full Stack Developer",
      type: "HTML • CSS • JavaScript • PHP(Yii2, Laravel etc.)",
      link: "#!",
    },
    {
      position: "Content Writer",
      type: "Creative • Research • Communication • SEO",
      link: "#!",
    }
  ];
  const uploadFIle = useRef();
  const [fileName, setFileName] = useState("");
  const onChangeFile = (e) => {
    setFileName(e.target.value);
  };
  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="position-relative">
          <Navbar type="light" />
        </div>
        <div className="pt__100 bg__gray-5">
          <section>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-10">
                  <h4 className="bold font__size--45 text__45-1024 text__45-md color__black-2 mb-3">
                    PHP Developer
                  </h4>
                  <h5 className="medium font__size--24 text__24-1024 text__24-sm text__24-xs color__gray-4 mb-0">
                    HTML • CSS • JavaScript • PHP(Yii2, Laravel etc.)
                  </h5>
                </div>
              </div>
            </div>
          </section>
        </div>
        <section>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <h5 className="bold font__size--20 text__20-1024 text__20-md mb-4">
                  Job Description
                </h5>
                
                <ul className="list__detail-career medium font__size--16 text__16-1024 text__16-md color__gray-4 pl-3 mb-5">
                    <li>Develop and maintain applications in Core PHP using MVC architecture and various PHP frameworks.</li>
                    <li>Develop & implement modern web services using service-oriented architecture, RESTful APIs, and SOAP.</li>
                    <li>Write and execute unit tests.</li>
                    <li>Implement front-end interfaces & experiences with modern HTML/CSS/jQuery.</li>
                    <li>Test, troubleshoot, and optimize application components for maximum speed, security, stability, and scalability.</li>
                  </ul>
                

                <h5 className="bold font__size--20 text__20-1024 text__20-md mb-4">
                  Skills & Experience
                </h5>
                
                <ul className="list__detail-career medium font__size--16 text__16-1024 text__16-md color__gray-4 pl-3 mb-5">
                  <li>Experience in Core PHP using MVC Architecture.</li>
                  <li>Knowledge of database operations with MySQL/MariaDB, Stored procedures.</li>
                  <li>Knowledge of front-end technologies (HTML5, CSS3, JavaScript, jQuery, etc.).</li>
                  <li>Knowledge and experience of OOPS.</li>
                  <li>Ability to write APIs including Restful APIs.</li>
                  <li>Knowledge of engineering practices like CI-CD.</li>
                  <li>Strong documentation, communication, and team collaboration skills.</li>
                  <li>Experience in iterative development methodologies like Agile.</li>
                </ul>
                
                <h5 className="bold font__size--45 text__45-1024 text__45-md mb-4">
                  Form Submission
                </h5>
                <p className="medium font__size--16 text__16-1024 text__16-md color__gray-4 mb-5 lh-2">
                  Grow with Cryptoxy Infotech
                </p>
                <div className="row">
                  <div class="form-group col-md-6">
                    <input
                      type="text"
                      class="form-control mb-4 input__field medium font__size--16 text__16-1024 text__16-md"
                      placeholder="Full Name"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <input
                      type="text"
                      class="form-control mb-4 input__field medium font__size--16 text__16-1024 text__16-md"
                      placeholder="Last Name"
                    />
                  </div>
                </div>
                <div className="row">
                  <div class="form-group col-md-6">
                    <input
                      type="email"
                      class="form-control mb-4 input__field medium font__size--16 text__16-1024 text__16-md"
                      placeholder="Email"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <div className="position-relative wrapper__form-upload d-flex align-items-center mb-4">
                      <p className="clamp__1 mr-2 w-100 medium font__size--16 text__16-1024 text__16-md mb-0">
                        {fileName ? fileName : "Upload your resume"}
                      </p>
                      <input
                        type="file"
                        onChange={onChangeFile}
                        ref={uploadFIle}
                      />
                      <div
                        className="icon pointer"
                        onClick={() => uploadFIle.current.click()}
                      >
                        <img src="./../images/jhfgjgf.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group mb-4">
                  <textarea
                    name=""
                    class="form-control mb-4 input__field textarea medium font__size--16 text__16-1024 text__16-md"
                    placeholder="Message or description"
                    cols="30"
                    rows="10"
                  ></textarea>
                </div>

                <div className="text-right">
                  <button className="bold font__size--14 text__14-1024 btn btn__green color__oldgreen shadow btn__original">
                    Sent
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg__gray-2">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="row mb-5">
                  <div className="col-md-10 col-xl-7">
                    <h4 className="bold font__size--45 text__45-1024 text__45-md color__black-2 mb-4">
                      Other Open Positions
                    </h4>
                    <p className="medium font__size--16 text__16-1024 text__16-md opacity__5 lh-2 mb-4">
                      Don't find your dream job ? Don't worry!! We've other positions available if you're interested in.
                    </p>
                  </div>
                </div>

                {openWork.map((obj) => {
                  return <OpenJob data={obj} />;
                })}
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </Fragment>
  );
};

export default DetailCareer;
