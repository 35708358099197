import React, { Fragment } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import ScrollToTop from "../component/other/ScrollToTop";

import Homepage from "./homepage/index";
import About from "./homepage/about";
import Career from "./homepage/career";
import Team from "./homepage/team";
import Blog from "./homepage/blog";
import Work from "./homepage/work";
import Detail from "./homepage/detail";
import WorkDetail from "./homepage/workDetail";
import DetailCareer from "./homepage/detailCareer";
import Contact from "./homepage/contact";
import Pricing from "./homepage/pricing";
import Terms from "./homepage/terms";
import Privacy from "./homepage/privacy";

const Index = (props) => {
  return (
    <Fragment>
      <Router forceRefresh>
        <ScrollToTop>
          <Switch>
            <Route exact path="/">
              <Homepage />
            </Route>
            <Route exact path="/about">
              <About />
            </Route>
            <Route exact path="/career">
              <Career />
            </Route>
            <Route exact path="/work">
              <Work />
            </Route>
            <Route exact path="/team">
              <Team />
            </Route>
            <Route exact path="/blog">
              <Blog />
            </Route>
            <Route exact path="/contact">
              <Contact />
            </Route>
            <Route exact path="/pricing">
              <Pricing />
            </Route>
            <Route exact path="/blog/detail">
              <Detail />
            </Route>
            <Route exact path="/work/detail">
              <WorkDetail />
            </Route>
            <Route exact path="/career/full-stack-developer">
              <DetailCareer />
            </Route>
            <Route exact path="/career/content-writer">
              <DetailCareer />
            </Route>
            <Route exact path="/terms-and-conditions">
              <Terms />
            </Route>
            <Route exact path="/privacy-policy">
              <Privacy />
            </Route>
          </Switch>
        </ScrollToTop>
      </Router>
    </Fragment>
  );
};

export default Index;
