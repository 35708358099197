import React, { Fragment, useState } from "react";
import Slider from "react-slick";

import Navbar from "../../component/navbar/Navbar";
import Footer from "../../component/footer/Footer";
import Client from "../../component/card/client";
import SliderAbout from "../../component/slider/SliderAbout";

const Pricing = () => {
  const ClientList = [
    "./../images/client/Company logo-11.png",
    "./../images/client/Company logo-10.png",
    "./../images/client/Company logo-9.png",
    "./../images/client/Company logo-8.png",
    "./../images/client/Company logo-7.png",
    "./../images/client/Company logo-6.png",
    "./../images/client/Company logo-5.png",
    "./../images/client/Company logo-4.png",
    "./../images/client/Company logo-3.png",
    "./../images/client/Company logo-2.png",
    "./../images/client/Company logo-1.png",
    "./../images/Company logo.png",
  ];

  const settings = {
    dots: false,
    infinite: true,
    centerMode: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const [faqStatus, setFaqStatus] = useState(0);
  const onClickStatus = (e) => {
    if (faqStatus == e) {
      setFaqStatus(0);
    } else {
      setFaqStatus(e);
    }
  };
  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="position-relative bg__oldgreen">
          <Navbar />
          <div className="pt__100">
            <section className="section__head about position-relative">
              <img src="./../images/PATTERN.png" className="path__1" alt="" />
              <div className="container position-relative z-2">
                <div className="text-center">
                  <h1 className="bold font__size--55 text__50-1024 text__50-sm text__50-xs color__white mb-0">
                    We have prepared various
                    <br className="d-none d-md-block" /> plans that might suit
                    you
                  </h1>
                  <p className="medium font__size--16 text__16-1024 text__16-md text__16-1024 text__16-md color__white opacity__5 my-4 lh-2">
                    Varius amet, integer tellus non eget viverra. Ultrices
                    tellus donec gravida id <br className="d-none d-md-block" />{" "}
                    sed senectus dolor cursus sed. Ullamcorper tellus ac cras
                    nec, pretium <br className="d-none d-md-block" /> laoreet
                    duis.{" "}
                  </p>

                  <a
                    href="#!"
                    className="bold font__size--14 text__14-1024 text__14-1024 btn btn__green color__oldgreen shadow btn__original"
                  >
                    Discover More
                  </a>
                </div>
              </div>
            </section>
          </div>
        </div>
        <section>
          <div className="container">
            <div className="wrapper__price-group">
              <div className="row justify-content-center">
                <div className="col-md-6 mb-4 mb-lg-0 col-lg-4">
                  <div className="wrapper__card-price">
                    <h5 className="bold font__size--32 text__32-1024 text__32-xs mb-0">
                      Lite
                    </h5>
                    <p className="py-3 medium font__size--14 text__14-1024 color__gray-3 lh-2 mb-0">
                      Pellentesque egestas massa nisl, neque scelerisque orci
                      interdum.{" "}
                    </p>
                    <h4 className="bold font__size--32 text__32-1024 text__32-xs">
                      $465
                      <span className="bold font__size--24 text__24-1024 text__24-sm text__24-xs color__gray-3">
                        /mo
                      </span>
                    </h4>
                    <hr />

                    <ul className="list__price-desc">
                      <li>
                        <div className="d-flex align-items-center medium font__size--14 text__14-1024">
                          <img src="./../images/sfsfdsf.png" alt="" />
                          <span className="ml-2">
                            Unlimited Task and Revisions
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center medium font__size--14 text__14-1024">
                          <img src="./../images/sfsfdsf.png" alt="" />
                          <span className="ml-2">Dedicated Slack Channel</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center medium font__size--14 text__14-1024">
                          <img src="./../images/sfsfdsf.png" alt="" />
                          <span className="ml-2">Personal Dashboard</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center medium font__size--14 text__14-1024">
                          <img src="./../images/sfsfdsf.png" alt="" />
                          <span className="ml-2">Landing page</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center medium font__size--14 text__14-1024 opacity__5">
                          <img src="./../images/sfsfdsf.png" alt="" />
                          <span className="ml-2">Social Media ads</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center medium font__size--14 text__14-1024 opacity__5">
                          <img src="./../images/sfsfdsf.png" alt="" />
                          <span className="ml-2">Brand Logo and Design</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center medium font__size--14 text__14-1024 opacity__5">
                          <img src="./../images/sfsfdsf.png" alt="" />
                          <span className="ml-2">Web Integrations</span>
                        </div>
                      </li>
                    </ul>

                    <a
                      href="#!"
                      className="bold font__size--14 text__14-1024 btn btn__gray-2 color__oldgreen w-100 mt-3"
                    >
                      Discover More
                    </a>
                  </div>
                </div>
                <div className="col-md-6 mb-4 mb-lg-0 col-lg-4">
                  <div className="wrapper__card-price">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="bold font__size--32 text__32-1024 text__32-xs mb-0">
                        Lite
                      </h5>
                      <span className="plan bold font__size--12 text__12-1024 color__oldgreen">
                        POPULAR PLAN
                      </span>
                    </div>
                    <p className="py-3 medium font__size--14 text__14-1024 color__gray-3 lh-2 mb-0">
                      Pellentesque egestas massa nisl, neque scelerisque orci
                      interdum.{" "}
                    </p>
                    <h4 className="bold font__size--32 text__32-1024 text__32-xs">
                      $465
                      <span className="bold font__size--24 text__24-1024 text__24-sm text__24-xs color__gray-3">
                        /mo
                      </span>
                    </h4>
                    <hr />

                    <ul className="list__price-desc">
                      <li>
                        <div className="d-flex align-items-center medium font__size--14 text__14-1024">
                          <img src="./../images/sfsfdsf.png" alt="" />
                          <span className="ml-2">
                            Unlimited Task and Revisions
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center medium font__size--14 text__14-1024">
                          <img src="./../images/sfsfdsf.png" alt="" />
                          <span className="ml-2">Dedicated Slack Channel</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center medium font__size--14 text__14-1024">
                          <img src="./../images/sfsfdsf.png" alt="" />
                          <span className="ml-2">Personal Dashboard</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center medium font__size--14 text__14-1024">
                          <img src="./../images/sfsfdsf.png" alt="" />
                          <span className="ml-2">Landing page</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center medium font__size--14 text__14-1024">
                          <img src="./../images/sfsfdsf.png" alt="" />
                          <span className="ml-2">Social Media ads</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center medium font__size--14 text__14-1024 opacity__5">
                          <img src="./../images/sfsfdsf.png" alt="" />
                          <span className="ml-2">Brand Logo and Design</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center medium font__size--14 text__14-1024 opacity__5">
                          <img src="./../images/sfsfdsf.png" alt="" />
                          <span className="ml-2">Web Integrations</span>
                        </div>
                      </li>
                    </ul>

                    <a
                      href="#!"
                      className="bold font__size--14 text__14-1024 btn btn__gray-2 color__oldgreen w-100 mt-3"
                    >
                      Discover More
                    </a>
                  </div>
                </div>
                <div className="col-md-6 mb-4 mb-lg-0 col-lg-4">
                  <div className="wrapper__card-price">
                    <h5 className="bold font__size--32 text__32-1024 text__32-xs mb-0">
                      Lite
                    </h5>
                    <p className="py-3 medium font__size--14 text__14-1024 color__gray-3 lh-2 mb-0">
                      Pellentesque egestas massa nisl, neque scelerisque orci
                      interdum.{" "}
                    </p>
                    <h4 className="bold font__size--32 text__32-1024 text__32-xs">
                      $465
                      <span className="bold font__size--24 text__24-1024 text__24-sm text__24-xs color__gray-3">
                        /mo
                      </span>
                    </h4>
                    <hr />

                    <ul className="list__price-desc">
                      <li>
                        <div className="d-flex align-items-center medium font__size--14 text__14-1024">
                          <img src="./../images/sfsfdsf.png" alt="" />
                          <span className="ml-2">
                            Unlimited Task and Revisions
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center medium font__size--14 text__14-1024">
                          <img src="./../images/sfsfdsf.png" alt="" />
                          <span className="ml-2">Dedicated Slack Channel</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center medium font__size--14 text__14-1024">
                          <img src="./../images/sfsfdsf.png" alt="" />
                          <span className="ml-2">Personal Dashboard</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center medium font__size--14 text__14-1024">
                          <img src="./../images/sfsfdsf.png" alt="" />
                          <span className="ml-2">Landing page</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center medium font__size--14 text__14-1024">
                          <img src="./../images/sfsfdsf.png" alt="" />
                          <span className="ml-2">Social Media ads</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center medium font__size--14 text__14-1024">
                          <img src="./../images/sfsfdsf.png" alt="" />
                          <span className="ml-2">Brand Logo and Design</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center medium font__size--14 text__14-1024">
                          <img src="./../images/sfsfdsf.png" alt="" />
                          <span className="ml-2">Web Integrations</span>
                        </div>
                      </li>
                    </ul>

                    <a
                      href="#!"
                      className="bold font__size--14 text__14-1024 btn btn__gray-2 color__oldgreen w-100 mt-3"
                    >
                      Discover More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="text-center">
            <h4 className="bold font__size--45 text__45-1024 text__45-md color__black-2 mb-5">
              Our satisfied clients
            </h4>

            <Slider {...settings} className="wrapper__client-list">
              {ClientList.map((obj) => {
                return (
                  <div className="items">
                    <Client img={obj} />
                  </div>
                );
              })}
            </Slider>
          </div>
        </section>

        <section className="bg__gray-5">
          <div className="text-center">
            <h4 className="bold font__size--45 text__45-1024 text__45-md color__black-2 mb-5">
              What do they say about us
            </h4>

            <SliderAbout />
            <div className="my-4">
              <SliderAbout rtl={true} />
            </div>
            <SliderAbout />
          </div>
        </section>

        <section>
          <div className="container">
            <h4 className="bold font__size--45 text__45-1024 text__45-md color__black-2 text-center mb-5">
              Frequently Asked Questions
            </h4>

            <div className="row justify-content-center">
              <div className="col-md-10">
                <div
                  className={
                    "wrapper__faq-card mb-4 " + (faqStatus == 1 ? "active" : "")
                  }
                >
                  <div
                    className="head pointer"
                    onClick={() => onClickStatus(1)}
                  >
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <h5 className="semi-bold font__size--16 text__16-1024 text__16-md mb-0">
                        Nisi orci feugiat odio sit lectus morbi massa mi id.
                        Euismod id interdum pellentesque{" "}
                      </h5>
                      <img
                        src="./../images/ghfsdf.png"
                        className="arrow"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="desc">
                    <p className="medium font__size--14 text__14-1024 text__14-1024 lh-2 mb-0">
                      In nec, malesuada viverra amet non. Scelerisque neque
                      libero eu eget. Pretium posuere faucibus risus scelerisque
                      condimentum. Arcu nulla venenatis enim dictum erat
                      vulputate ullamcorper pharetra, vitae. Eget metus aliquam
                      arcu sed porta nunc tincidunt tincidunt. Turpis amet, elit
                      sed id urna integer sed urna.
                    </p>
                  </div>
                </div>
                <div
                  className={
                    "wrapper__faq-card mb-4 " + (faqStatus == 2 ? "active" : "")
                  }
                >
                  <div
                    className="head pointer"
                    onClick={() => onClickStatus(2)}
                  >
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <h5 className="semi-bold font__size--16 text__16-1024 text__16-md mb-0">
                        Nisi orci feugiat odio sit lectus morbi massa mi id.
                        Euismod id interdum pellentesque{" "}
                      </h5>
                      <img
                        src="./../images/ghfsdf.png"
                        className="arrow"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="desc">
                    <p className="medium font__size--14 text__14-1024 text__14-1024 lh-2 mb-0">
                      In nec, malesuada viverra amet non. Scelerisque neque
                      libero eu eget. Pretium posuere faucibus risus scelerisque
                      condimentum. Arcu nulla venenatis enim dictum erat
                      vulputate ullamcorper pharetra, vitae. Eget metus aliquam
                      arcu sed porta nunc tincidunt tincidunt. Turpis amet, elit
                      sed id urna integer sed urna.
                    </p>
                  </div>
                </div>
                <div
                  className={
                    "wrapper__faq-card mb-4 " + (faqStatus == 3 ? "active" : "")
                  }
                >
                  <div
                    className="head pointer"
                    onClick={() => onClickStatus(3)}
                  >
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <h5 className="semi-bold font__size--16 text__16-1024 text__16-md mb-0">
                        Nisi orci feugiat odio sit lectus morbi massa mi id.
                        Euismod id interdum pellentesque{" "}
                      </h5>
                      <img
                        src="./../images/ghfsdf.png"
                        className="arrow"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="desc">
                    <p className="medium font__size--14 text__14-1024 text__14-1024 lh-2 mb-0">
                      In nec, malesuada viverra amet non. Scelerisque neque
                      libero eu eget. Pretium posuere faucibus risus scelerisque
                      condimentum. Arcu nulla venenatis enim dictum erat
                      vulputate ullamcorper pharetra, vitae. Eget metus aliquam
                      arcu sed porta nunc tincidunt tincidunt. Turpis amet, elit
                      sed id urna integer sed urna.
                    </p>
                  </div>
                </div>
                <div
                  className={
                    "wrapper__faq-card mb-4 " + (faqStatus == 4 ? "active" : "")
                  }
                >
                  <div
                    className="head pointer"
                    onClick={() => onClickStatus(4)}
                  >
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <h5 className="semi-bold font__size--16 text__16-1024 text__16-md mb-0">
                        Nisi orci feugiat odio sit lectus morbi massa mi id.
                        Euismod id interdum pellentesque{" "}
                      </h5>
                      <img
                        src="./../images/ghfsdf.png"
                        className="arrow"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="desc">
                    <p className="medium font__size--14 text__14-1024 text__14-1024 lh-2 mb-0">
                      In nec, malesuada viverra amet non. Scelerisque neque
                      libero eu eget. Pretium posuere faucibus risus scelerisque
                      condimentum. Arcu nulla venenatis enim dictum erat
                      vulputate ullamcorper pharetra, vitae. Eget metus aliquam
                      arcu sed porta nunc tincidunt tincidunt. Turpis amet, elit
                      sed id urna integer sed urna.
                    </p>
                  </div>
                </div>
                <div
                  className={
                    "wrapper__faq-card mb-4 " + (faqStatus == 5 ? "active" : "")
                  }
                >
                  <div
                    className="head pointer"
                    onClick={() => onClickStatus(5)}
                  >
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <h5 className="semi-bold font__size--16 text__16-1024 text__16-md mb-0">
                        Nisi orci feugiat odio sit lectus morbi massa mi id.
                        Euismod id interdum pellentesque{" "}
                      </h5>
                      <img
                        src="./../images/ghfsdf.png"
                        className="arrow"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="desc">
                    <p className="medium font__size--14 text__14-1024 text__14-1024 lh-2 mb-0">
                      In nec, malesuada viverra amet non. Scelerisque neque
                      libero eu eget. Pretium posuere faucibus risus scelerisque
                      condimentum. Arcu nulla venenatis enim dictum erat
                      vulputate ullamcorper pharetra, vitae. Eget metus aliquam
                      arcu sed porta nunc tincidunt tincidunt. Turpis amet, elit
                      sed id urna integer sed urna.
                    </p>
                  </div>
                </div>
                <div
                  className={
                    "wrapper__faq-card mb-4 " + (faqStatus == 6 ? "active" : "")
                  }
                >
                  <div
                    className="head pointer"
                    onClick={() => onClickStatus(6)}
                  >
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <h5 className="semi-bold font__size--16 text__16-1024 text__16-md mb-0">
                        Nisi orci feugiat odio sit lectus morbi massa mi id.
                        Euismod id interdum pellentesque{" "}
                      </h5>
                      <img
                        src="./../images/ghfsdf.png"
                        className="arrow"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="desc">
                    <p className="medium font__size--14 text__14-1024 text__14-1024 lh-2 mb-0">
                      In nec, malesuada viverra amet non. Scelerisque neque
                      libero eu eget. Pretium posuere faucibus risus scelerisque
                      condimentum. Arcu nulla venenatis enim dictum erat
                      vulputate ullamcorper pharetra, vitae. Eget metus aliquam
                      arcu sed porta nunc tincidunt tincidunt. Turpis amet, elit
                      sed id urna integer sed urna.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </Fragment>
  );
};

export default Pricing;
