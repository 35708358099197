import React, { Component, Fragment } from "react";
import Slider from "react-slick";

const SliderAbout = (props) => {
  const tetsimonial = [];

  for (let index = 0; index < 12; index++) {
    tetsimonial.push(
      <div className="items">
        <div className="wrapper__card-tetsimonial bg__white">
          <p className="medium font__size--14 text__14-1024 lh-2 mb-3">
            Eget fusce massa quis lectus. Massa senectus mauris bibendum
            pulvinar volutpat dictum. Purus, non id aliquet nec odio arcu
            lacinia ac vel. Et orci ut diam mattis orci porta mi gravida
            bibendum.{" "}
          </p>
          <div className="profile">
            <img src="./../images/Ellipse 11.png" className="img" alt="" />
            <div className="ml-2">
              <h5 className="bold font__size--14 text__14-1024 mb-0">
                Rico Jonathan
              </h5>
              <p className="mb-0 medium font__size--12 text__12-1024 oapcity__5">
                Founder and CEO of DRONE
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    centerMode: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    pauseOnHover: true,
    rtl: props.rtl ? props.rtl : false,
    responsive: [
      {
        breakpoint: 1030,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Fragment>
      <Slider {...settings} className="wrapper__slider-about text-left">
        {tetsimonial}
      </Slider>
    </Fragment>
  );
};

export default SliderAbout;
