import React, { Fragment, useEffect } from "react";
import $ from "jquery";

import Navbar from "../../component/navbar/Navbar";
import Footer from "../../component/footer/Footer";
import OpenJob from "../../component/card/OpenJob";
import ProfileTeam from "../../component/card/ProfileTeam";
const Team = () => {
  const profileTeam = [
    {
      img: "./../images/team/IMAGE-7.png",
      name: "James Draymond",
      title: "Founder and CEO",
      link: "#!",
    },
    {
      img: "./../images/team/IMAGE-6.png",
      name: "Anton Samy",
      title: "CMO",
      link: "#!",
    },
    {
      img: "./../images/team/IMAGE-5.png",
      name: "Samantha Key",
      title: "CFO",
      link: "#!",
    },
    {
      img: "./../images/team/IMAGE-4.png",
      name: "Tomy Lilard",
      title: "COO",
      link: "#!",
    },
    {
      img: "./../images/team/IMAGE-3.png",
      name: "Richard John",
      title: "Lead Developer",
      link: "#!",
    },
    {
      img: "./../images/team/IMAGE-2.png",
      name: "Natasha Vinyl",
      title: "Lead Marketing",
      link: "#!",
    },
    {
      img: "./../images/team/IMAGE-1.png",
      name: "Nichole Rose",
      title: "Lead Designer",
      link: "#!",
    },
    {
      img: "./../images/team/IMAGE.png",
      name: "Casey Angel",
      title: "Lead QA Engineer",
      link: "#!",
    },
    {
      img: "./../images/team/IMAGE-7.png",
      name: "James Draymond",
      title: "Founder and CEO",
      link: "#!",
    },
    {
      img: "./../images/team/IMAGE-6.png",
      name: "Anton Samy",
      title: "CMO",
      link: "#!",
    },
    {
      img: "./../images/team/IMAGE-5.png",
      name: "Samantha Key",
      title: "CFO",
      link: "#!",
    },
    {
      img: "./../images/team/IMAGE-4.png",
      name: "Tomy Lilard",
      title: "COO",
      link: "#!",
    },
    {
      img: "./../images/team/IMAGE-3.png",
      name: "Richard John",
      title: "Lead Developer",
      link: "#!",
    },
    {
      img: "./../images/team/IMAGE-2.png",
      name: "Natasha Vinyl",
      title: "Lead Marketing",
      link: "#!",
    },
    {
      img: "./../images/team/IMAGE-1.png",
      name: "Nichole Rose",
      title: "Lead Designer",
      link: "#!",
    },
    {
      img: "./../images/team/IMAGE.png",
      name: "Casey Angel",
      title: "Lead QA Engineer",
      link: "#!",
    },
    {
      img: "./../images/team/IMAGE-3.png",
      name: "Richard John",
      title: "Lead Developer",
      link: "#!",
    },
    {
      img: "./../images/team/IMAGE-2.png",
      name: "Natasha Vinyl",
      title: "Lead Marketing",
      link: "#!",
    },
    {
      img: "./../images/team/IMAGE-1.png",
      name: "Nichole Rose",
      title: "Lead Designer",
      link: "#!",
    },
    {
      img: "./../images/team/IMAGE.png",
      name: "Casey Angel",
      title: "Lead QA Engineer",
      link: "#!",
    },
  ];

  useEffect(() => {
    $("a[href^='#click-']").on("click", function (e) {
      e.preventDefault();
      $("html, body").animate(
        {
          scrollTop: $($(this).attr("href")).offset().top,
        },
        1000
      );
    });
  }, []);

  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="position-relative bg__oldgreen">
          <Navbar />
          <div className="pt__100">
            <section className="section__head about position-relative">
              <img src="./../images/PATTERN.png" className="path__1" alt="" />
              <div className="container position-relative z-2">
                <div className="text-center">
                  <h1 className="bold font__size--55 text__50-1024 text__50-sm text__50-xs color__white mb-0">
                    We are talented people who
                    <br className="d-none d-md-block" /> create stories
                  </h1>
                  <p className="medium font__size--16 text__16-1024 text__16-md color__white opacity__5 my-4 lh-2">
                    Varius amet, integer tellus non eget viverra. Ultrices
                    tellus donec gravida id <br className="d-none d-md-block" />{" "}
                    sed senectus dolor cursus sed. Ullamcorper tellus ac cras
                    nec, pretium <br className="d-none d-md-block" /> laoreet
                    duis.{" "}
                  </p>

                  <a
                    href="#click-to"
                    className="bold font__size--14 text__14-1024 btn btn__green color__oldgreen shadow btn__original"
                  >
                    Discover More
                  </a>
                </div>
              </div>
            </section>
          </div>
        </div>
        <section>
          <div className="container container__1024-none">
            <div className="wrapper__list-img">
              <div className="wrap">
                <img src="./../images/fsdsf.png" alt="" />
                <img src="./../images/sdadad.png" alt="" />
                <img src="./../images/dsdsad.png" alt="" />
                <img src="./../images/IMAGE (3).png" alt="" />
              </div>
            </div>
          </div>
        </section>

        <section className="bg__gray-5" id="click-to">
          <div className="container">
            <h4 className="bold font__size--45 text__45-1024 text__45-md color__black-2 text-center mb-5">
              We are not just a team, we are family
            </h4>

            <div className="row">
              {profileTeam.map((obj) => {
                return (
                  <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                    <ProfileTeam data={obj} />
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <h4 className="bold font__size--45 text__45-1024 text__45-md color__black-2 text-center mb-3">
              Working remotely is not a hindrance
            </h4>
            <p className="medium font__size--16 text__16-1024 text__16-md opacity__5 text-center lh-2 mb-5">
              Working with us doesn't make you have to come to the office every
              day, you can work <br className="d-none d-md-block" /> wherever you want even while on vacation
              in any part of the world
            </p>

            <div className="text-center">
              <img src="./../images/MAP.png" alt="" />
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </Fragment>
  );
};

export default Team;
