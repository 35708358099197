import React, { Fragment, useEffect } from "react";
import $ from "jquery";

import Navbar from "../../component/navbar/Navbar";
import Footer from "../../component/footer/Footer";
import OpenJob from "../../component/card/OpenJob";
const Career = () => {
  const openWork = [
    {
      position: "Full Stack Developer",
      type: "HTML • CSS • JavaScript • PHP(Yii2, Laravel etc.)",
      link: "/career/full-stack-developer",
    },
    {
      position: "Content Writer",
      type: "Creative • Research • Communication • SEO",
      link: "/career/content-writer",
    }
  ];

  const Benefit = [
    "Flexible scheduling",
    "Remote work",
    "Career development",
    "Work/life balance",
  ];

  useEffect(() => {
    $("a[href^='#click-']").on("click", function (e) {
      e.preventDefault();
      $("html, body").animate(
        {
          scrollTop: $($(this).attr("href")).offset().top,
        },
        1000
      );
    });
  }, []);
  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="position-relative bg__oldgreen">
          <Navbar />
          <div className="pt__100">
            <section className="section__head career position-relative">
              <img src="./../images/PATTERN.png" className="path__1" alt="" />
              <div className="container position-relative z-2">
                <div className="text-center">
                  <h1 className="bold font__size--55 text__50-1024 text__50-sm text__50-xs color__white mb-0">
                    Find a role that suits you,{" "}
                    <br className="d-none d-md-block" /> and join us
                  </h1>
                  <p className="medium font__size--16 text__16-1024 text__16-md color__white opacity__5 my-4 lh-2">
                    We have high regard for skillful, hardworking and talented individuals.<br className="d-none d-md-block" /> 
                    If you are passionate about your career, have a creative flair and excellent craft skill, Join us!{" "}
                  </p>

                  <a
                    href="#click-to"
                    className="bold font__size--14 text__14-1024 btn btn__green color__oldgreen shadow btn__original"
                  >
                    Discover More
                  </a>
                </div>
              </div>
            </section>
          </div>
        </div>
        <section>
          <div className="container">
            <img
              src="./../images/sadasdsad.png"
              className="position-relative images__career"
              alt=""
            />
          </div>
        </section>

        <section id="click-to">
          <div className="container">
            <div className="row">
              <div className="order-md-1 order-12 col-md-5 col-lg-4 mt-4 my-md-auto">
                <div className="row">
                  <div className="col-sm-6 mb-4 mb-md-0 col-md-12">
                    <div className="wrapper__count-work d-flex align-items-center mb-5">
                      <div className="icon">
                        <img src="./../images/sadsad.png" alt="" />
                      </div>
                      <div className="ml-3">
                        <h5 className="bold font__size--45 text__45-1024 text__45-md color__oldgreen">
                          40+
                        </h5>
                        <p className="medium font__size--24 text__24-1024 text__24-sm text__24-xs opacity__5 color__black-2 mb-0">
                          Good People
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 mb-4 mb-md-0 col-md-12">
                    <div className="wrapper__count-work d-flex align-items-center mb-5">
                      <div className="icon">
                        <img src="./../images/dfgdfg.png" alt="" />
                      </div>
                      <div className="ml-3">
                        <h5 className="bold font__size--45 text__45-1024 text__45-md color__oldgreen">
                          4
                        </h5>
                        <p className="medium font__size--24 text__24-1024 text__24-sm text__24-xs opacity__5 color__black-2 mb-0">
                          Different Country
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 mb-4 mb-md-0 col-md-12">
                    <div className="wrapper__count-work d-flex align-items-center">
                      <div className="icon">
                        <img src="./../images/gdfgdf.png" alt="" />
                      </div>
                      <div className="ml-3">
                        <h5 className="bold font__size--45 text__45-1024 text__45-md color__oldgreen">
                          3
                        </h5>
                        <p className="medium font__size--24 text__24-1024 text__24-sm text__24-xs opacity__5 color__black-2 mb-0">
                          Roles available
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="order-1 order-md-12 col-md-7 my-auto">
                <h4 className="bold font__size--45 text__45-1024 text__45-md color__black-2 mb-4">
                  We work together and create something great
                </h4>
                <p className="medium font__size--16 text__16-1024 text__16-md opacity__5 lh-2 mb-4">
                  We are a digital experience agency building consumer driven products and platforms. 
                  We work towards bringing human to technology. This journey has been made possible because of highly 
                  capable, motivated and passionate people with diverse backgrounds and perspectives. 
                  We are proud of what we make and who we are. 
                </p>
                <p className="medium font__size--16 text__16-1024 text__16-md opacity__5 lh-2 ">
                  Cryptoxy is a digital product & platforms development company focussed on creating user-centric 
                  solutions in the form of web apps, mobile apps, data-driven dashboards, and user experience 
                  management tools.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="bg__gray-2">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="row mb-5">
                  <div className="col-md-10 col-xl-7">
                    <h4 className="bold font__size--45 text__45-1024 text__45-md color__black-2 mb-4">
                      Open Positions
                    </h4>
                    <p className="medium font__size--16 text__16-1024 text__16-md opacity__5 lh-2 mb-4">
                      Nec non, et sed semper suspendisse. Sapien, ridiculus
                      euismod varius faucibus feugiat et dignissim porta id.
                      Facilisi neque nec id nunc massa. Nisl nibh faucibus nunc
                      vel.
                    </p>
                  </div>
                </div>

                {openWork.map((obj) => {
                  return <OpenJob data={obj} />;
                })}
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <h4 className="bold font__size--45 text__45-1024 text__45-md color__black-2 mb-5 text-center">
              Perks and benefit
            </h4>

            <div className="row">
              {Benefit.map((obj, i) => {
                return (
                  <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                    <div className="wrapper__card-benefit">
                      <h5 className="bold font__size--20 text__20-1024 text__20-md color__oldgreen mb__4">
                        {i + 1 <= 9 ? "0" + (i + 1) : i + 1}
                      </h5>
                      <h5 className="semi-bold font__size--20 text__20-1024 text__20-md color__black-2">
                        {obj}
                      </h5>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        <section className="position-relative">
          <div className="path__3 d-none d-md-block"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-5 col-lg-4 text-center text-md-left mb-5 my-md-auto">
                <img src="./../images/Group 147.png" alt="" />
              </div>
              <div className="col-md-7 pl-md-5 my-auto">
                <h5 className="bold font__size--18 text__18-1024 color__oldgreen ls__4 mb-3">
                  OUR MISSION
                </h5>
                <h4 className="bold font__size--45 text__45-1024 text__45-md color__black-2 mb-4">
                  How we reach our goal
                </h4>
                <p className="medium font__size--16 text__16-1024 text__16-md opacity__5 lh-2 mb-4">
                  Nec non, et sed semper suspendisse. Sapien, ridiculus euismod
                  varius faucibus feugiat et dignissim porta id. Facilisi neque
                  nec id nunc massa. Nisl nibh faucibus nunc vel. Vulputate
                  pellentesque fringilla orci praesent vel cursus dui. Imperdiet
                  euismod tempus, massa rutrum. Gravida augue purus non, cursus
                  quam cum ultricies. Pellentesque blandit sit ut magna enim.
                </p>
                <p className="medium font__size--16 text__16-1024 text__16-md opacity__5 lh-2 ">
                  Tellus aliquet a faucibus et eget elementum faucibus. Duis
                  scelerisque diam non ullamcorper. Sed quis netus et fames
                  elementum. Pellentesque quisque tristique consectetur risus
                  adipiscing. Facilisi et a congue nam phasellus tristique orci
                  morbi fermentum. Egestas egestas netus diam turpis sodales
                  eget.
                </p>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </Fragment>
  );
};

export default Career;
