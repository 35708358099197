import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <Fragment>
      <section className="section__contact pb-0 position-relative">
        <div className="container">
          <div className="wrapper__contact-us bg__oldgreen text-center position-relative">
            <img src="./../images/dsadsad.png" className="path__4" alt="" />
            <h3 className="bold font__size--55 text__50-1024 text__50-sm text__50-xs color__white mb-4 position-relative z-2">
              Have a project idea to <br className="d-none d-sm-block" />{" "}
              collaborate with?
            </h3>
            <NavLink
                    to="/contact"
              className="bold font__size--14 text__14-1024 btn btn__green color__oldgreen shadow btn__original shadow position-relative z-2"
            >
              Contact Us
            </NavLink>
          </div>
        </div>
      </section>
      <section className="bg__black-2 pb-0 section__footer">
        <div className="container">
          <div className="row mb-5">
            <div className="col-sm-6 mb-4 mb-mb-0 col-md-3">
              <img src="./../images/LOGO (3).png" className="mb-4" alt=""  height={40}/>
              <ul className="list__footer-menu">
                <li>
                  <div className="d-flex align-items-center">
                    <img src="./../images/Frame (5).png" alt="" />
                    <span className="semi-bold color__white opacity__5 font__size--14 text__14-1024 ml-2">
                      cryptoxy.in
                    </span>
                  </div>
                </li>
                <li>
                  <div className="d-flex align-items-center">
                    <img src="./../images/Frame (6).png" alt="" />
                    <span className="semi-bold color__white opacity__5 font__size--14 text__14-1024 ml-2">
                      hello@cryptoxy.in
                    </span>
                  </div>
                </li>
                <li>
                  <div className="d-flex align-items-center">
                    <img src="./../images/Frame (7).png" alt="" />
                    <span className="semi-bold color__white opacity__5 font__size--14 text__14-1024 ml-2">
                      +91 979 939 9555
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-6 col-sm-6 mb-4 mb-mb-0 col-md offset-md-1 offset-lg-2">
              <h5 className="mb-4 bold font__size--16 text__16-1024 text__16-md color__white">
                Company
              </h5>
              <ul className="list__footer-menu">
                <li>
                  <NavLink
                    to="/about"
                    className="semi-bold font__size--14 text__14-1024 color__white opacity__5 hover"
                  >
                    About Us
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/contact"
                    className="semi-bold font__size--14 text__14-1024 color__white opacity__5 hover"
                  >
                    Contact
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/career"
                    className="semi-bold font__size--14 text__14-1024 color__white opacity__5 hover"
                  >
                    Career
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/blog"
                    className="semi-bold font__size--14 text__14-1024 color__white opacity__5 hover"
                  >
                    Blog
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="col-6 col-sm-6 mb-4 mb-mb-0 col-md">
              <h5 className="mb-4 bold font__size--16 text__16-1024 text__16-md color__white">
                Project
              </h5>
              <ul className="list__footer-menu">
                <li>
                  <NavLink
                    to="/work"
                    className="semi-bold font__size--14 text__14-1024 color__white opacity__5 hover"
                  >
                    Works
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/team"
                    className="semi-bold font__size--14 text__14-1024 color__white opacity__5 hover"
                  >
                    Team
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/pricing"
                    className="semi-bold font__size--14 text__14-1024 color__white opacity__5 hover"
                  >
                    Pricing
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="col-6 col-sm-6 mb-4 mb-mb-0 col-md-2">
              <h5 className="mb-4 bold font__size--16 text__16-1024 text__16-md color__white">
                Support
              </h5>
              <ul className="list__footer-menu">
                <li>
                  <NavLink
                    to="/terms-and-conditions"
                    className="semi-bold font__size--14 text__14-1024 color__white opacity__5 hover"
                  >
                    Terms and Conditions
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/privacy-policy"
                    className="semi-bold font__size--14 text__14-1024 color__white opacity__5 hover"
                  >
                    Privacy Policy
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="col-6 col-sm-6 mb-4 mb-mb-0 col-md-2">
              <h5 className="mb-4 bold font__size--16 text__16-1024 text__16-md color__white">
                Support
              </h5>
              <ul className="list__footer-sosmed">
                <li>
                  <a href="#!" className="opacity__5 hover">
                    <img src="./../images/sdad.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#!" className="opacity__5 hover">
                    <img src="./../images/sdasd.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#!" className="opacity__5 hover">
                    <img src="./../images/asdsad.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#!" className="opacity__5 hover">
                    <img src="./../images/Frame (8).png" alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <hr className="hr__footer mb-0" />
          <div className="text-center bold font__size--12 text__12-1024 color__white opacity__5 py-4">
            2022 Cryptoxy Infotech Pvt Ltd. All Rights Reserved
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Footer;
