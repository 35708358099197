import React, { Fragment, useState } from "react";

import Navbar from "../../component/navbar/Navbar";
import Footer from "../../component/footer/Footer";

import CardBlog from "../../component/card/CardBlog";

const Detail = () => {
  const [blogData, setBlogData] = useState([
    {
      img: "./../images/blog/IMAGE.png",
      title: "How is our process in working on the product design",
      desc: "Laoreet donec nibh orci est integer. Vitae faucibus consectetur id semper euismod sit. Cras maecenas nec pellentesque neque, eu. Adipiscing dignissim magna fusce feugiat enim, urna.",
      created: "Loius Nathan",
      job: "Sr. Brand Designer",
      profile: "./../images/PHOTO.png",
      link: "/blog/detail",
    },
    {
      img: "./../images/blog/IMAGE-1.png",
      title: "How is our process in working on the product design",
      desc: "Laoreet donec nibh orci est integer. Vitae faucibus consectetur id semper euismod sit. Cras maecenas nec pellentesque neque, eu. Adipiscing dignissim magna fusce feugiat enim, urna.",
      created: "Loius Nathan",
      job: "Sr. Brand Designer",
      profile: "./../images/PHOTO.png",
      link: "/blog/detail",
    },
    {
      img: "./../images/blog/IMAGE-2.png",
      title: "How is our process in working on the product design",
      desc: "Laoreet donec nibh orci est integer. Vitae faucibus consectetur id semper euismod sit. Cras maecenas nec pellentesque neque, eu. Adipiscing dignissim magna fusce feugiat enim, urna.",
      created: "Loius Nathan",
      job: "Sr. Brand Designer",
      profile: "./../images/PHOTO.png",
      link: "/blog/detail",
    },
  ]);
  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="position-relative">
          <Navbar type="light" />
        </div>
        <div className="pt__100">
          <section>
            <div className="container">
              <img
                src="./../images/rtrxc.png"
                className="images__single-blog mb-4"
                alt=""
              />
              <h4 className="bold font__size--45 text__45-1024 text__45-md mb-4 color__black-2">
                How is our process in working on the brand design
              </h4>

              <div className="wrapper__profile d-flex align-items-center mb-5">
                <img src="./../images/hjhgjj.png" className="img" alt="" />
                <div className="ml-3">
                  <h5 className="bold font__size--20 text__20-1024 text__20-md mb-1">Loius Nathan</h5>
                  <p className="medium font__size--14 text__14-1024 opacity__5 mb-0">
                    Sr. Brand Designer
                  </p>
                </div>
              </div>

              <div className="row justify-content-center mb-5">
                <div className="col-md-10">
                  <p className="medium color__black-2 font__size--16 text__16-1024 text__16-md opacity__7 mb-4 lh-2">
                    Dui sed elementum, ornare etiam mauris purus eu quam.
                    Commodo posuere duis pellentesque vel etiam turpis sed.
                    Fermentum eleifend ut neque, fringilla nisl convallis. Nibh
                    tincidunt diam aliquam, et. Adipiscing consequat amet, at
                    mattis magna amet, urna duis. Ut purus tincidunt viverra
                    faucibus cursus convallis aliquam. Amet mi nibh sed neque,
                    phasellus neque. Eget imperdiet eget ut laoreet cursus enim.
                    Placerat vitae id tempor tempus ullamcorper arcu fermentum
                    viverra. Diam morbi tellus amet eget consequat. Volutpat
                    enim tortor ut in. Tristique scelerisque volutpat odio
                    volutpat vivamus sed eget posuere nec. Vitae id at sit ut.
                    Ornare nulla ligula eu in. Condimentum pulvinar sed
                    suspendisse malesuada magnis. Amet, nulla venenatis arcu eu
                    non, hendrerit ornare id tincidunt. Tristique ultrices fames
                    pellentesque tortor tortor, id. Amet ut turpis rhoncus amet
                    enim eget ut. Vehicula diam leo, tellus non non. Sed
                    facilisis non dignissim neque, suscipit donec amet.
                  </p>
                  <p className="medium color__black-2 font__size--16 text__16-1024 text__16-md opacity__7 mb-4 lh-2">
                    Malesuada mauris amet, nibh auctor pulvinar tempus, diam
                    iaculis. Leo, dolor donec lacus malesuada sit diam.
                    Fermentum viverra sagittis aenean et netus. Consequat amet
                    facilisi ut magna placerat. Sed nunc ornare sed egestas enim
                    donec ultrices. Eu felis neque, dictum sit justo faucibus a.
                    At parturient in pellentesque sit imperdiet malesuada. In
                    molestie diam nunc sit. Tortor tellus, nulla etiam massa
                    donec pretium eu a velit. Donec maecenas massa convallis
                    scelerisque quis et. Penatibus libero pulvinar amet,
                    pretium. Eget vitae pulvinar consectetur cras. Consectetur
                    consectetur ac fames arcu, tortor, volutpat orci
                    condimentum. Vivamus proin placerat condimentum porttitor a.
                    Vitae pretium, lobortis quis non eu. Id viverra consequat
                    sapien nibh. Maecenas nec pulvinar neque, ac sit vitae
                    turpis congue sed. Accumsan, netus velit turpis urna, justo
                    suspendisse parturient tempus. Nullam erat et mollis
                    venenatis augue.
                  </p>

                  <h5 className="bold font__size--24 text__24-1024 text__24-sm text__24-xs color__black-2">
                    1. Wireframe
                  </h5>
                  <img
                    src="./../images/unsplash_tZc3vjPCk-Q.png"
                    className="my-4 images__single-blog-2"
                    alt=""
                  />
                  <p className="medium color__black-2 font__size--16 text__16-1024 text__16-md opacity__7 mb-4 lh-2">
                    Cursus enim tristique elit mattis condimentum. Urna vivamus
                    commodo lacus non lectus eleifend quis velit. Lobortis eget
                    tristique aliquam lacus sit sem. Nec tincidunt sed quis ut
                    arcu, et orci. Vitae mollis scelerisque ac eu adipiscing
                    vitae accumsan. Bibendum nulla tellus volutpat turpis
                    tristique leo leo. Accumsan enim ullamcorper sed egestas ut
                    consequat accumsan. Sed arcu semper cursus iaculis purus et
                    vitae ullamcorper. Pellentesque dui aliquam hendrerit eget
                    tristique varius. Lorem tincidunt ac sed euismod morbi
                    suspendisse risus cras cursus. Duis a nisi volutpat arcu
                    dui.
                  </p>

                  <h5 className="bold font__size--24 text__24-1024 text__24-sm text__24-xs color__black-2">
                    2. User Flow
                  </h5>
                  <img
                    src="./../images/unsplash_qC2n6RQU4Vw.png"
                    className="my-4 images__single-blog-2"
                    alt=""
                  />
                  <p className="medium color__black-2 font__size--16 text__16-1024 text__16-md opacity__7 mb-4 lh-2">
                    Amet dui, amet eget pretium sodales odio condimentum varius.
                    Ornare vulputate habitant sem gravida. Tellus nec tempus
                    enim facilisis quam consequat. Leo ac faucibus id posuere
                    mauris gravida pretium consectetur congue. Quis aliquam
                    gravida ut id purus ac tellus, amet blandit. Nulla diam,
                    tempor integer mattis et. Venenatis eget mi vulputate quam
                    in pellentesque. At velit arcu malesuada non lectus id
                    elementum.
                  </p>

                  <h5 className="bold font__size--24 text__24-1024 text__24-sm text__24-xs color__black-2">
                    3. High Fidelity
                  </h5>
                  <img
                    src="./../images/unsplash_FeuEg-8XlA8 (1).png"
                    className="my-4 images__single-blog-2"
                    alt=""
                  />
                  <p className="medium color__black-2 font__size--16 text__16-1024 text__16-md opacity__7 mb-4 lh-2">
                    Amet dui, amet eget pretium sodales odio condimentum varius.
                    Ornare vulputate habitant sem gravida. Tellus nec tempus
                    enim facilisis quam consequat. Leo ac faucibus id posuere
                    mauris gravida pretium consectetur congue. Quis aliquam
                    gravida ut id purus ac tellus, amet blandit. Nulla diam,
                    tempor integer mattis et. Venenatis eget mi vulputate quam
                    in pellentesque. At velit arcu malesuada non lectus id
                    elementum.
                  </p>

                  <h5 className="bold font__size--24 text__24-1024 text__24-sm text__24-xs color__black-2">
                    4. Development
                  </h5>
                  <img
                    src="./../images/unsplash_ieic5Tq8YMk.png"
                    className="my-4 images__single-blog-2"
                    alt=""
                  />
                  <p className="medium color__black-2 font__size--16 text__16-1024 text__16-md opacity__7 mb-4 lh-2">
                    Amet dui, amet eget pretium sodales odio condimentum varius.
                    Ornare vulputate habitant sem gravida. Tellus nec tempus
                    enim facilisis quam consequat. Leo ac faucibus id posuere
                    mauris gravida pretium consectetur congue. Quis aliquam
                    gravida ut id purus ac tellus, amet blandit. Nulla diam,
                    tempor integer mattis et. Venenatis eget mi vulputate quam
                    in pellentesque. At velit arcu malesuada non lectus id
                    elementum.
                  </p>
                </div>
              </div>

              <h4 className="bold font__size--45 text__45-1024 text__45-md mb-5 color__black-2">
                More Blog
              </h4>
              <div className="row">
                {blogData.map((obj) => {
                  return (
                    <div className="col-md-6 mb-4 mb-md-0 col-lg-4">
                      <CardBlog data={obj} />
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        </div>

        <Footer />
      </div>
    </Fragment>
  );
};

export default Detail;
