import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";

const CardWork = (props) => {
  return (
    <Fragment>
      <div className="wrapper__card-project">
        <div
          className={
            "preview d-flex justify-content-center align-items-center " +
            (props.data.class ? props.data.class : "")
          }
        >
          <img src={props.data.img} alt="" />
        </div>
        <div className="mt-4 mb-md-4 mb-3">
          <h5 className="bold font__size--24 text__24-1024 text__24-sm text__24-xs">{props.data.title}</h5>
          <p className="medium font__size--14 text__14-1024 opacity__5 lh-2 mb-0">
            {props.data.desc}
          </p>
        </div>
        <NavLink to={props.data.link} className="d-inline-block color__oldgreen">
          <div className="d-flex align-items-center bold font__size--15 text__14-1024">
            See Detail Project
            <img src="./../images/ARROW.png" className="ml-2" alt="" />
          </div>
        </NavLink>
      </div>
    </Fragment>
  );
};

export default CardWork;
