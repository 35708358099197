import React, { Fragment, useState, useEffect } from "react";
import $ from "jquery";

import Navbar from "../../component/navbar/Navbar";
import Footer from "../../component/footer/Footer";

import CardBlog from "../../component/card/CardBlog";
const Blog = () => {
  const [blogData, setBlogData] = useState([
    {
      img: "./../images/blog/IMAGE.png",
      title: "How is our process in working on the product design",
      desc: "Laoreet donec nibh orci est integer. Vitae faucibus consectetur id semper euismod sit. Cras maecenas nec pellentesque neque, eu. Adipiscing dignissim magna fusce feugiat enim, urna.",
      created: "Loius Nathan",
      job: "Sr. Brand Designer",
      profile: "./../images/PHOTO.png",
      link: "/blog/detail",
    },
    {
      img: "./../images/blog/IMAGE-1.png",
      title: "How is our process in working on the product design",
      desc: "Laoreet donec nibh orci est integer. Vitae faucibus consectetur id semper euismod sit. Cras maecenas nec pellentesque neque, eu. Adipiscing dignissim magna fusce feugiat enim, urna.",
      created: "Loius Nathan",
      job: "Sr. Brand Designer",
      profile: "./../images/PHOTO.png",
      link: "/blog/detail",
    },
    {
      img: "./../images/blog/IMAGE-2.png",
      title: "How is our process in working on the product design",
      desc: "Laoreet donec nibh orci est integer. Vitae faucibus consectetur id semper euismod sit. Cras maecenas nec pellentesque neque, eu. Adipiscing dignissim magna fusce feugiat enim, urna.",
      created: "Loius Nathan",
      job: "Sr. Brand Designer",
      profile: "./../images/PHOTO.png",
      link: "/blog/detail",
    },
    {
      img: "./../images/blog/IMAGE-3.png",
      title: "How is our process in working on the product design",
      desc: "Laoreet donec nibh orci est integer. Vitae faucibus consectetur id semper euismod sit. Cras maecenas nec pellentesque neque, eu. Adipiscing dignissim magna fusce feugiat enim, urna.",
      created: "Loius Nathan",
      job: "Sr. Brand Designer",
      profile: "./../images/PHOTO.png",
      link: "/blog/detail",
    },
    {
      img: "./../images/blog/IMAGE-4.png",
      title: "How is our process in working on the product design",
      desc: "Laoreet donec nibh orci est integer. Vitae faucibus consectetur id semper euismod sit. Cras maecenas nec pellentesque neque, eu. Adipiscing dignissim magna fusce feugiat enim, urna.",
      created: "Loius Nathan",
      job: "Sr. Brand Designer",
      profile: "./../images/PHOTO.png",
      link: "/blog/detail",
    },
    {
      img: "./../images/blog/IMAGE-5.png",
      title: "How is our process in working on the product design",
      desc: "Laoreet donec nibh orci est integer. Vitae faucibus consectetur id semper euismod sit. Cras maecenas nec pellentesque neque, eu. Adipiscing dignissim magna fusce feugiat enim, urna.",
      created: "Loius Nathan",
      job: "Sr. Brand Designer",
      profile: "./../images/PHOTO.png",
      link: "/blog/detail",
    },
    {
      img: "./../images/blog/IMAGE-6.png",
      title: "How is our process in working on the product design",
      desc: "Laoreet donec nibh orci est integer. Vitae faucibus consectetur id semper euismod sit. Cras maecenas nec pellentesque neque, eu. Adipiscing dignissim magna fusce feugiat enim, urna.",
      created: "Loius Nathan",
      job: "Sr. Brand Designer",
      profile: "./../images/PHOTO.png",
      link: "/blog/detail",
    },
    {
      img: "./../images/blog/IMAGE-7.png",
      title: "How is our process in working on the product design",
      desc: "Laoreet donec nibh orci est integer. Vitae faucibus consectetur id semper euismod sit. Cras maecenas nec pellentesque neque, eu. Adipiscing dignissim magna fusce feugiat enim, urna.",
      created: "Loius Nathan",
      job: "Sr. Brand Designer",
      profile: "./../images/PHOTO.png",
      link: "/blog/detail",
    },
    {
      img: "./../images/blog/unsplash_hGV2TfOh0ns.png",
      title: "How is our process in working on the product design",
      desc: "Laoreet donec nibh orci est integer. Vitae faucibus consectetur id semper euismod sit. Cras maecenas nec pellentesque neque, eu. Adipiscing dignissim magna fusce feugiat enim, urna.",
      created: "Loius Nathan",
      job: "Sr. Brand Designer",
      profile: "./../images/PHOTO.png",
      link: "/blog/detail",
    },
  ]);

  useEffect(() => {
    $("a[href^='#click-']").on("click", function (e) {
      e.preventDefault();
      $("html, body").animate(
        {
          scrollTop: $($(this).attr("href")).offset().top,
        },
        1000
      );
    });
  }, []);

  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="position-relative bg__oldgreen">
          <Navbar />
          <div className="pt__100">
            <section className="section__head blog position-relative">
              <img src="./../images/PATTERN.png" className="path__1" alt="" />
              <div className="container position-relative z-2">
                <div className="text-center">
                  <h1 className="bold font__size--55 text__50-1024 text__50-sm text__50-xs color__white mb-0">
                    Check out our various blogs <br className="d-none d-md-block" /> for your new knowledge
                  </h1>
                  <p className="medium font__size--16 text__16-1024 text__16-md color__white opacity__5 my-4 lh-2">
                    Varius amet, integer tellus non eget viverra. Ultrices
                    tellus donec gravida id <br className="d-none d-md-block" /> sed senectus dolor cursus
                    sed. Ullamcorper tellus ac cras nec, pretium <br className="d-none d-md-block" /> laoreet
                    duis.{" "}
                  </p>

                  <a
                    href="#click-to"
                    className="bold font__size--14 text__14-1024 btn btn__green color__oldgreen shadow btn__original"
                  >
                    Discover More
                  </a>
                </div>
              </div>
            </section>
          </div>
        </div>
        <section>
          <div className="container">
            <div className="wrapper__card-blog-head position-relative" id="click-to">
              <div className="row">
                <div className="col-md-6 mb-4 my-md-auto">
                  <img
                    src="./../images/jhgjhk.png"
                    className="images__blog-head"
                    alt=""
                  />
                </div>
                <div className="col-md-6 my-auto">
                  <h4 className="bold font__size--45 text__45-1024 text__45-md mb-4">
                    How to increase productivity
                  </h4>
                  <p className="medium font__size--16 text__16-1024 text__16-md opacity__5 lh-2 mb-4">
                    Nec non, et sed semper suspendisse. Sapien, ridiculus
                    euismod varius faucibus feugiat et dignissim porta id.
                    Facilisi neque nec id nunc massa. Nisl nibh faucibus nunc
                    vel. Vulputate pellentesque fringilla orci praesent vel
                    cursus dui. Imperdiet euismod tempus, massa rutrum. Gravida
                    augue purus non, cursus quam cum ultricies. Pellentesque
                    blandit sit ut magna enim.
                  </p>

                  <div className="profile d-flex align-items-center">
                    <img src="./../images/PHOTO.png" alt="" className="img" />
                    <div className="ml-3">
                      <h5 className="bold font__size--14 text__14-1024 color__black-2 mb-1">
                        Jaxson Torff
                      </h5>
                      <p className="mb-0 medium font__size-12 text__12-1024 opacity__5 color__black-2">
                        Brand Designer
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              {blogData.map((obj) => {
                return (
                  <div className="col-md-6 col-lg-4 mb-5">
                    <CardBlog data={obj} />
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </Fragment>
  );
};

export default Blog;
