import React, { Component, Fragment } from "react";

const ProfileTeam = (props) => {
  return (
    <Fragment>
      <div className="wrapper__card-team text-center bg__white">
        <img src={props.data.img} className="img mb-3" alt="" />
        <h5 className="bold font__size--24 text__24-1024 text__24-sm text__24-xs color__black-2">
          {props.data.name}
        </h5>
        <p className="medium font__size--16 text__16-1024 text__16-md opacity__5">{props.data.title}</p>
        {/* <a href={props.data.link}>
          <img src="./../images/sasasaa.png" alt="" />
        </a> */}
      </div>
    </Fragment>
  );
};

export default ProfileTeam;
