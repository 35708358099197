import React, { Component, Fragment } from "react";

const Client = (props) => {
  return (
    <Fragment>
      <div className={"wrapper__card-client"}>
        <img src={props.img} alt="" />
      </div>
    </Fragment>
  );
};

export default Client;
