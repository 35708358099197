import React, { Fragment, useState } from "react";

import Navbar from "../../component/navbar/Navbar";
import Footer from "../../component/footer/Footer";

import CardWork from "../../component/card/CardWork";

const WorkDetail = () => {
  const WorkList = [
    {
      img: "./../images/MOCKUP.png",
      title: "Real Estate Agency Web",
      desc: "Varius amet, integer tellus non eget viverra. Ultrices tellus donec gravida id sed senectus dolor cursus sed. ",
      class: "",
      link: "/work/detail",
    },
    {
      img: "./../images/MOCKUP (1).png",
      title: "Property Agent Website",
      desc: "Varius amet, integer tellus non eget viverra. Ultrices tellus donec gravida id sed senectus dolor cursus sed. ",
      class: "",
      link: "/work/detail",
    },
    {
      img: "./../images/MOCKUP (2).png",
      title: "Movie Apps Mobile",
      desc: "Varius amet, integer tellus non eget viverra. Ultrices tellus donec gravida id sed senectus dolor cursus sed. ",
      class: "",
      link: "/work/detail",
    },
  ];
  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="position-relative">
          <Navbar type="light" />
        </div>
        <div className="pt__100">
          <section>
            <div className="container">
              <div className="row">
                <div className="col-md-5">
                  <h4 className="bold font__size--45 text__45-1024 text__45-md mb-md-0">
                    E-commerce <br className="d-none d-md-block" /> mobile app
                  </h4>
                </div>
                <div className="col-md-7">
                  <p className="medium font__size--16 text__16-1024 text__16-md opacity__5 lh-2 mb-0">
                    Dui sed elementum, ornare etiam mauris purus eu quam.
                    Commodo posuere duis pellentesque vel etiam turpis sed.
                    Fermentum eleifend ut neque, fringilla nisl convallis. Nibh
                    tincidunt diam aliquam, et. Adipiscing consequat amet, at
                    mattis magna amet, urna duis. Ut purus tincidunt viverra
                    faucibus cursus convallis aliquam. Amet mi nibh sed neque,
                    phasellus neque. Eget imperdiet eget ut laoreet cursus enim.
                    Placerat vitae id tempor tempus ullamcorper arcu fermentum
                    viverra. Diam morbi tellus amet eget consequat. Volutpat
                    enim tortor ut in.
                  </p>
                </div>
              </div>

              <div className="wrapper__head-slide my-5 text-center">
                <img src="./../images/iPhone 13 Pro.png" alt="" />
              </div>

              <div className="row mt__5">
                <div className="col-md-6 offset-md-1">
                  <h4 className="bold font__size--45 text__45-1024 text__45-md mb-4">Client</h4>
                  <p className="medium font__size--16 text__16-1024 text__16-md opacity__5 lh-2 mb-0">
                    Dui sed elementum, ornare etiam mauris purus eu quam.
                    Commodo posuere duis pellentesque vel etiam turpis sed.
                    Fermentum eleifend ut neque, fringilla nisl convallis. Nibh
                    tincidunt diam aliquam, et. Adipiscing consequat amet, at
                    mattis magna amet, urna duis. Ut purus tincidunt viverra
                    faucibus cursus convallis aliquam. Amet mi nibh sed neque,
                    phasellus neque. Eget imperdiet eget ut laoreet cursus enim.
                    Placerat vitae id tempor tempus ullamcorper arcu fermentum
                    viverra. Diam morbi tellus amet eget consequat. Volutpat
                    enim tortor ut in.
                  </p>
                </div>
              </div>

              <div className="row mt__5">
                <div className="col-md-6 offset-md-4">
                  <h4 className="bold font__size--45 text__45-1024 text__45-md mb-4">Challenge</h4>
                  <p className="medium font__size--16 text__16-1024 text__16-md opacity__5 lh-2 mb-0">
                    Dui sed elementum, ornare etiam mauris purus eu quam.
                    Commodo posuere duis pellentesque vel etiam turpis sed.
                    Fermentum eleifend ut neque, fringilla nisl convallis. Nibh
                    tincidunt diam aliquam, et. Adipiscing consequat amet, at
                    mattis magna amet, urna duis. Ut purus tincidunt viverra
                    faucibus cursus convallis aliquam. Amet mi nibh sed neque,
                    phasellus neque. Eget imperdiet eget ut laoreet cursus enim.
                    Placerat vitae id tempor tempus ullamcorper arcu fermentum
                    viverra. Diam morbi tellus amet eget consequat. Volutpat
                    enim tortor ut in.
                  </p>
                </div>
              </div>

              <div className="wrapper__video-play position-relative my-5">
                <img src="./../images/IMAGE.png" alt="" />
                <img
                  src="./../images/BTN - PLAY.png"
                  className="play pointer"
                  alt=""
                />
              </div>

              <div className="row mt__5 mb-5">
                <div className="col-md-6 mb-4 mb-md-0">
                  <h4 className="bold font__size--45 text__45-1024 text__45-md mb-4">Problems</h4>
                  <p className="medium font__size--16 text__16-1024 text__16-md opacity__5 lh-2 mb-0">
                    Dui sed elementum, ornare etiam mauris purus eu quam.
                    Commodo posuere duis pellentesque vel etiam turpis sed.
                    Fermentum eleifend ut neque, fringilla nisl convallis. Nibh
                    tincidunt diam aliquam, et. Adipiscing consequat amet, at
                    mattis magna amet, urna duis. Ut purus tincidunt viverra
                    faucibus cursus convallis aliquam. Amet mi nibh sed neque,
                    phasellus neque. Eget imperdiet eget ut laoreet cursus enim.
                    Placerat vitae id tempor tempus ullamcorper arcu fermentum
                    viverra. Diam morbi tellus amet eget consequat. Volutpat
                    enim tortor ut in.
                  </p>
                </div>
                <div className="col-md-6">
                  <h4 className="bold font__size--45 text__45-1024 text__45-md mb-4">Solutions</h4>
                  <p className="medium font__size--16 text__16-1024 text__16-md opacity__5 lh-2 mb-0">
                    Dui sed elementum, ornare etiam mauris purus eu quam.
                    Commodo posuere duis pellentesque vel etiam turpis sed.
                    Fermentum eleifend ut neque, fringilla nisl convallis. Nibh
                    tincidunt diam aliquam, et. Adipiscing consequat amet, at
                    mattis magna amet, urna duis. Ut purus tincidunt viverra
                    faucibus cursus convallis aliquam. Amet mi nibh sed neque,
                    phasellus neque. Eget imperdiet eget ut laoreet cursus enim.
                    Placerat vitae id tempor tempus ullamcorper arcu fermentum
                    viverra. Diam morbi tellus amet eget consequat. Volutpat
                    enim tortor ut in.
                  </p>
                </div>
              </div>

              <h4 className="bold font__size--45 text__45-1024 text__45-md mt__5 mb-4">More Project</h4>
              <div className="row justify-content-between">
                {WorkList.map((obj, i) => {
                  return (
                    <div className="col-md-6 mb-4 mb-lg-0 col-lg-4">
                      <CardWork data={obj} />
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        </div>

        <Footer />
      </div>
    </Fragment>
  );
};

export default WorkDetail;
