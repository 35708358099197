import React, { Fragment, useRef } from "react";
import Slider from "react-slick";

import Navbar from "../../component/navbar/Navbar";
import Footer from "../../component/footer/Footer";
import Client from "../../component/card/client";
import ProfileTeam from "../../component/card/ProfileTeam";
import { NavLink } from "react-router-dom";

const About = () => {
  const tetsimonial = [];

  const ClientList = [
    "./../images/client/Company logo-11.png",
    "./../images/client/Company logo-10.png",
    "./../images/client/Company logo-9.png",
    "./../images/client/Company logo-8.png",
    "./../images/client/Company logo-7.png",
    "./../images/client/Company logo-6.png",
    "./../images/client/Company logo-5.png",
    "./../images/client/Company logo-4.png",
    "./../images/client/Company logo-3.png",
    "./../images/client/Company logo-2.png",
    "./../images/client/Company logo-1.png",
    "./../images/Company logo.png",
  ];

  const profileTeam = [
    {
      img: "./../images/team/IMAGE-7.png",
      name: "Ravindra Chaudhary",
      title: "Captain",
      link: "#!",
    },
    {
      img: "./../images/team/IMAGE-5.png",
      name: "Sunita Poonia",
      title: "Mate",
      link: "#!",
    },
    {
      img: "./../images/team/IMAGE-6.png",
      name: "Pardeep Kumar",
      title: "Bosun",
      link: "#!",
    },
    {
      img: "./../images/team/IMAGE-4.png",
      name: "Vikash Kumar",
      title: "Chief Engineer",
      link: "#!",
    },
    {
      img: "./../images/team/IMAGE-3.png",
      name: "Virendra Singh",
      title: "2nd Engineer",
      link: "#!",
    },
    {
      img: "./../images/team/IMAGE-2.png",
      name: "Meenu Kumari",
      title: "Cook",
      link: "#!",
    },
  ];

  for (let index = 0; index < 12; index++) {
    tetsimonial.push(
      <div className="items">
        <div className="wrapper__card-tetsimonial bg__white">
          <p className="medium font__size--14 text__14-1024 lh-2 mb-3">
            Eget fusce massa quis lectus. Massa senectus mauris bibendum
            pulvinar volutpat dictum. Purus, non id aliquet nec odio arcu
            lacinia ac vel. Et orci ut diam mattis orci porta mi gravida
            bibendum.{" "}
          </p>
          <div className="profile">
            <img src="./../images/Ellipse 11.png" className="img" alt="" />
            <div className="ml-2">
              <h5 className="bold font__size--14 text__14-1024 mb-0">
                Rico Jonathan
              </h5>
              <p className="mb-0 medium font__size--12 text__12-1024 oapcity__5">
                Founder and CEO of DRONE
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 3,
  };

  const testimonailArrows = useRef();

  const gotoTestiNext = () => {
    testimonailArrows.current.slickNext();
  };

  const gotoTestiPrev = () => {
    testimonailArrows.current.slickPrev();
  };

  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="position-relative bg__oldgreen">
          <Navbar />
          <div className="pt__100">
            <section className="section__head position-relative">
              <img src="./../images/PATTERN.png" className="path__1" alt="" />
              <div className="container position-relative z-2">
                <div className="row">
                  <div className="col-md-6 my-auto">
                    <h1 className="bold font__size--55 text__50-1024 text__50-sm text__50-xs color__white mb-0">
                      Get to know us more and why we exist
                    </h1>
                  </div>
                  <div className="col-md-6 my-auto">
                    <p className="medium font__size--16 text__16-1024 text__16-md color__white opacity__5 my-4 lh-2">
                      We consult, market research to know your competiter, design & build your business
                      for tomorrow's success today.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section>
              <div className="container"></div>
            </section>
          </div>
        </div>
        <section>
          <div className="container">
            <img
              src="./../images/sdsadsa.png"
              className="position-relative images__about mb-5"
              alt=""
            />
            <div className="row">
              <div className="col-sm-6 mb-4 mb-md-0 col-md-3 text-center position-relative line__right mm__none">
                <h5 className="bold font__size--45 text__45-1024 text__45-md color__oldgreen">
                  50+
                </h5>
                <p className="mb-0 medium font__size--24 text__24-1024 text__24-sm text__24-xs opacity__5">
                  Project Completed
                </p>
              </div>
              <div className="col-sm-6 mb-4 mb-md-0 col-md-3 text-center position-relative line__right mm__none sm__none">
                <h5 className="bold font__size--45 text__45-1024 text__45-md color__oldgreen">
                  2+
                </h5>
                <p className="mb-0 medium font__size--24 text__24-1024 text__24-sm text__24-xs opacity__5">
                  Award winner
                </p>
              </div>
              <div className="col-sm-6 mb-4 mb-md-0 col-md-3 text-center position-relative line__right mm__none">
                <h5 className="bold font__size--45 text__45-1024 text__45-md color__oldgreen">
                  100+
                </h5>
                <p className="mb-0 medium font__size--24 text__24-1024 text__24-sm text__24-xs opacity__5">
                  Satisfied Client
                </p>
              </div>
              <div className="col-sm-6 mb-4 mb-md-0 col-md-3 text-center position-relative">
                <h5 className="bold font__size--45 text__45-1024 text__45-md color__oldgreen">
                  150+{" "}
                </h5>
                <p className="mb-0 medium font__size--24 text__24-1024 text__24-sm text__24-xs opacity__5">
                  Good Testimonials
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="position-relative">
          <div className="path__3 d-none d-md-block"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-5 col-lg-4 text-center text-md-left mb-5 my-md-auto">
                <img src="./../images/sadasd.png" alt="" />
              </div>
              <div className="col-md-7 pl-md-5 my-auto">
                <h4 className="bold font__size--45 text__45-1024 text__45-md color__black-2">
                  Why choose us
                </h4>
                <p className="medium font__size--16 text__16-1024 text__16-md opacity__5 lh-2 mb-4">
                  In a world where development is happening very fast, we need to live with it or else we may be left behind. 
                  We don't just help your business live with it, we help you stay one step ahead of it.
                </p>
                <p className="medium font__size--16 text__16-1024 text__16-md opacity__5 lh-2 ">
                  When we collaborate together, you can count on:
                  <ul>
                    <li>Experts of their fields (Analystics, UI/UX, Development etc.)</li>
                    <li>Agile process from start to end</li>
                    <li>Continous delivery</li>
                    <li>Flawless UI/UX for exceptional experience</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <h4 className="bold font__size--45 text__45-1024 text__45-md color__black-2 text-center mb-5">
              Our satisfied clients
            </h4>

            <div className="row">
              {ClientList.map((obj) => {
                return (
                  <div className="col-6 col-sm-4 col-md-3 mb-4">
                    <Client img={obj} />
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        <section className="bg__gray-2">
          <div className="container">
            <h4 className="bold font__size--45 text__45-1024 text__45-md color__black-2 text-center mb-5">
              Let’s meet our crew
            </h4>

            <div className="row mb-5">
              {profileTeam.map((obj) => {
                return (
                  <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                    <ProfileTeam data={obj} />
                  </div>
                );
              })}
            </div>
            <div className="text-center">
              <NavLink
                to="/team"
                className="bold font__size--14 text__14-1024 btn btn__outlined--oldgreen color__oldgreen shadow btn__original no__opacity shadow ml-3 h__green"
              >
                Explore our team
              </NavLink>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="text-center mb-5">
              <h4 className="bold font__size--45 text__45-1024 text__45-md color__black-2">
                Our Process
              </h4>
              <p className="font__size--16 text__16-1024 text__16-md opacity__5 lh-2">
                Our customer-centric, agile approach gets your products to market faster for a competitive edge.{" "}
              </p>
            </div>

            <div className="row">
              <div className="col-md-6 mb-4 mb-lg-0 col-lg-3">
                <div className="wrapper__card-process position-realtive">
                  <img src="./../images/hfghf.png" className="img" alt="" />
                  <h5 className="bold font__size--16 text__16-1024 text__16-md color__oldgreen number position-realtive z-2">
                    01
                  </h5>
                  <h5 className="bols font__size--24 text__24-1024 text__24-sm text__24-xs color__black-2 position-realtive z-2">
                    Research
                  </h5>
                  <p className="medium font__size--12 text__12-1024 opacity__5 lh-2 mb-0 position-realtive z-2">
                    We spend time researching about the project and competitor so that we can come up with better 
                    approach which helps us to stand out in the market.<br/><br/>{" "}
                  </p>
                </div>
              </div>
              <div className="col-md-6 mb-4 mb-lg-0 col-lg-3">
                <div className="wrapper__card-process position-realtive">
                  <img src="./../images/hfghf.png" className="img" alt="" />
                  <h5 className="bold font__size--16 text__16-1024 text__16-md color__oldgreen number position-realtive z-2">
                    02
                  </h5>
                  <h5 className="bols font__size--24 text__24-1024 text__24-sm text__24-xs color__black-2 position-realtive z-2">
                    Wireframe
                  </h5>
                  <p className="medium font__size--12 text__12-1024 opacity__5 lh-2 mb-0 position-realtive z-2">
                    Once we are done with the research part, we create high fidelity wireframes that help you 
                    understand your project better. Wireframes are almost reflection of your website.<br/><br/>{" "}
                  </p>
                </div>
              </div>
              <div className="col-md-6 mb-4 mb-lg-0 col-lg-3">
                <div className="wrapper__card-process position-realtive">
                  <img src="./../images/hfghf.png" className="img" alt="" />
                  <h5 className="bold font__size--16 text__16-1024 text__16-md color__oldgreen number position-realtive z-2">
                    03
                  </h5>
                  <h5 className="bols font__size--24 text__24-1024 text__24-sm text__24-xs color__black-2 position-realtive z-2">
                    Design
                  </h5>
                  <p className="medium font__size--12 text__12-1024 opacity__5 lh-2 mb-0 position-realtive z-2">
                    When we're done with the previous phases we move on to the next phase i.e design. While designing we take care of better UI/UX which helps your 
                     customers to navigate &amp; understand your project easily.{" "}
                  </p>
                </div>
              </div>
              <div className="col-md-6 mb-4 mb-lg-0 col-lg-3">
                <div className="wrapper__card-process position-realtive">
                  <img src="./../images/hfghf.png" className="img" alt="" />
                  <h5 className="bold font__size--16 text__16-1024 text__16-md color__oldgreen number position-realtive z-2">
                    04
                  </h5>
                  <h5 className="bols font__size--24 text__24-1024 text__24-sm text__24-xs color__black-2 position-realtive z-2">
                    Development
                  </h5>
                  <p className="medium font__size--12 text__12-1024 opacity__5 lh-2 mb-0 position-realtive z-2">
                  Once all the steps are completed we start our development process. While developing we still take 
                  care of future scope so if we need to add more functionality then it can be done over a 
                  period of time.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </Fragment>
  );
};

export default About;
