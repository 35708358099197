import React, { Fragment, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";

import Navbar from "../../component/navbar/Navbar";
import Footer from "../../component/footer/Footer";
import { map } from "jquery";

const Contact = () => {
  const category = [
    "UIUX Design",
    "Motion Design",
    "Prototype",
    "UX Research",
    "Graphic Design",
  ];

  const [selectCategoty, setSelectCategoty] = useState("");

  const onSelectCategory = (e) => {
    if (selectCategoty == e) {
      setSelectCategoty("");
    } else {
      setSelectCategoty(e);
    }
  };

  const budget = [
    "< $5000",
    "$5,000 - $10,000",
    "$10,000 - $30,000",
    "$30,000",
  ];

  const [selectBudget, setSelectBudget] = useState("");

  const onSelectBudget = (e) => {
    if (selectBudget == e) {
      setSelectBudget("");
    } else {
      setSelectBudget(e);
    }
  };

  useEffect(() => {
    $("a[href^='#click-']").on("click", function (e) {
      e.preventDefault();
      $("html, body").animate(
        {
          scrollTop: $($(this).attr("href")).offset().top,
        },
        1000
      );
    });
  }, []);

  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="position-relative bg__oldgreen">
          <Navbar />
          <div className="pt__100">
            <section className="section__head conact position-relative">
              <img src="./../images/PATTERN.png" className="path__1" alt="" />
              <div className="container position-relative z-2">
                <div className="text-center">
                  <h1 className="bold font__size--55 text__50-1024 text__50-sm text__50-xs color__white mb-0">
                    Contact us to give a project{" "}
                    <br className="d-none d-md-block" /> or just say hello! 👋
                  </h1>
                  <p className="medium font__size--16 text__16-1024 text__16-md color__white opacity__5 my-4 lh-2">
                    Cryptoxy is a leading business solution provider with expertise in web and mobile app, AI, Blockchain, Chatbot, and 360° <br className="d-none d-md-block" />{" "}visual experience development. 
                    Have a project idea or just a few queries, let us know, and we will be glad to be of service to you.
                    <br className="d-none d-md-block" />
                    Fill the below form, and you will be soon hearing from us.
                    {" "}
                  </p>

                  <a
                    href="#click-to"
                    className="bold font__size--14 text__14-1024 btn btn__green color__oldgreen shadow btn__original"
                  >
                    Contact us
                  </a>
                </div>
              </div>
            </section>
          </div>
        </div>
        <section id="click-to">
          <div className="container">
            <div className="wrapper__contact-wrap position-relative">
              <div className="mb-5 navtab position-relative">
                <ul
                  class="nav nav-pills wrapper__navtab-contact"
                  id="pills-tab"
                  role="tablist"
                >
                  <li class="nav-item">
                    <a
                      class="nav-link color__gray-3 bold font__size--24 text__24-1024 text__24-sm text__24-xs active"
                      id="pills-contact-tab"
                      data-toggle="pill"
                      href="#pills-contact"
                      role="tab"
                      aria-controls="pills-contact"
                      aria-selected="true"
                    >
                      Contact us
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link color__gray-3 bold font__size--24 text__24-1024 text__24-sm text__24-xs"
                      id="pills-work-tab"
                      data-toggle="pill"
                      href="#pills-work"
                      role="tab"
                      aria-controls="pills-work"
                      aria-selected="false"
                    >
                      Work with us
                    </a>
                  </li>
                </ul>
              </div>
              <div className="contact">
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-contact"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                  >
                    <div className="text-center mb-5">
                      <h4 className="bold font__size--45 text__45-1024 text__45-md mb-4">
                        Say hello to us !
                      </h4>
                      <p className="medium font__size--16 text__16-1024 text__16-md lh-2 mb-4">
                        <span className="opacity__5">
                          Write anything you want to say to say hello to us, or
                          you can <br className="d-none d-sm-block" /> send an
                          email to
                        </span>{" "}
                        <a
                          className="color__black-2 bold"
                          href="mailto:hello@cryptoxy.in"
                        >
                          hello@cryptoxy.in
                        </a>
                      </p>
                    </div>

                    <div className="row">
                      <div class="form-group col-md-6">
                        <input
                          type="text"
                          class="form-control mb-4 input__field medium font__size--16 text__16-1024 text__16-md"
                          placeholder="First Name"
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <input
                          type="text"
                          class="form-control mb-4 input__field medium font__size--16 text__16-1024 text__16-md"
                          placeholder="Last Name"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div class="form-group col-md-6">
                        <input
                          type="text"
                          class="form-control mb-4 input__field medium font__size--16 text__16-1024 text__16-md"
                          placeholder="Phone"
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <input
                          type="text"
                          class="form-control mb-4 input__field medium font__size--16 text__16-1024 text__16-md"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div class="form-group mb-4">
                      <input
                        type="text"
                        class="form-control mb-4 input__field medium font__size--16 text__16-1024 text__16-md"
                        placeholder="Company or organization"
                      />
                    </div>
                    <div class="form-group mb-4">
                      <textarea
                        name=""
                        class="form-control mb-4 input__field textarea medium font__size--16 text__16-1024 text__16-md"
                        placeholder="Message or description"
                        cols="30"
                        rows="10"
                      ></textarea>
                    </div>

                    <div className="text-right">
                      <button className="bold font__size--14 text__14-1024 btn btn__green color__oldgreen shadow btn__original">
                        Send
                      </button>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-work"
                    role="tabpanel"
                    aria-labelledby="pills-work-tab"
                  >
                    <div className="text-center mb-5">
                      <h4 className="bold font__size--45 text__45-1024 text__45-md mb-4">
                        Trust your project to us
                      </h4>
                      <p className="medium font__size--16 text__16-1024 text__16-md lh-2 mb-4">
                        <span className="opacity__5">
                          Write anything you want to say to say hello to us, or
                          you can <br className="d-none d-md-block" /> send an
                          email to
                        </span>{" "}
                        <a
                          className="color__black-2 bold"
                          href="mailto:hello@cryptoxy.in"
                        >
                          hello@cryptoxy.in
                        </a>
                      </p>
                    </div>

                    <div className="row">
                      <div class="form-group col-md-6">
                        <input
                          type="text"
                          class="form-control mb-4 input__field medium font__size--16 text__16-1024 text__16-md"
                          placeholder="First Name"
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <input
                          type="text"
                          class="form-control mb-4 input__field medium font__size--16 text__16-1024 text__16-md"
                          placeholder="Last Name"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div class="form-group col-md-6">
                        <input
                          type="text"
                          class="form-control mb-4 input__field medium font__size--16 text__16-1024 text__16-md"
                          placeholder="Phone"
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <input
                          type="text"
                          class="form-control mb-4 input__field medium font__size--16 text__16-1024 text__16-md"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div class="form-group mb-4">
                      <input
                        type="text"
                        class="form-control mb-4 input__field medium font__size--16 text__16-1024 text__16-md"
                        placeholder="Company or organization"
                      />
                    </div>

                    <h5 className="bold font__size--16 text__16-1024 text__16-md color__black-2 mb-3">
                      Project Category
                    </h5>

                    <div className="wrapper__select-option mb-4">
                      {category.map((obj) => {
                        return (
                          <div
                            onClick={(e) => onSelectCategory(obj)}
                            className={
                              "list medium pointer font__size--16 text__16-1024 text__16-md color__black-2 opacity__5 " +
                              (selectCategoty == obj ? "active" : "")
                            }
                          >
                            {obj}
                          </div>
                        );
                      })}
                    </div>

                    <h5 className="bold font__size--16 text__16-1024 text__16-md color__black-2 mb-3">
                      Your Budget
                    </h5>

                    <div className="wrapper__select-option mb-4">
                      {budget.map((obj) => {
                        return (
                          <div
                            onClick={(e) => onSelectBudget(obj)}
                            className={
                              "list medium pointer font__size--16 text__16-1024 text__16-md color__black-2 opacity__5 " +
                              (selectBudget == obj ? "active" : "")
                            }
                          >
                            {obj}
                          </div>
                        );
                      })}
                    </div>

                    <div class="form-group mb-4">
                      <textarea
                        name=""
                        class="form-control mb-4 input__field textarea medium font__size--16 text__16-1024 text__16-md"
                        placeholder="Message or description"
                        cols="30"
                        rows="10"
                      ></textarea>
                    </div>

                    <div className="text-right">
                      <button className="bold font__size--14 text__14-1024 btn btn__green color__oldgreen shadow btn__original">
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg__gray-2">
          <div className="container">
            <div className="row">
              <div className="col-md-5 col-lg-4 text-center text-md-left mb-5 my-md-auto">
                <img src="./../images/gjhgj.png" alt="" />
              </div>
              <div className="col-md-7 pl-md-5 my-auto">
                <h1 className="bold font__size--55 text__50-1024 text__50-sm text__50-xs color__black-2 mb-0">
                  Get in touch with us
                </h1>
                <p className="medium font__size--16 text__16-1024 text__16-md color__black opacity__5 mt-4 mb-0 lh-2">
                  Nec non, et sed semper suspendisse. Sapien, ridiculus euismod
                  varius faucibus feugiat et dignissim porta id. Facilisi neque
                  nec id nunc massa. Nisl nibh faucibus nunc vel.
                </p>

                <div className="row mt-4 mt-md-0">
                  <div className="col-6 col-xl-5 mb-4 mt-md-5">
                    <img src="./../images/xcxzv.png" alt="" />
                    <h5 className="bold font__size--24 text__24-1024 text__24-sm text__24-xs mt-4 color__black-2 mb-0">
                      hello@cryptoxy.in
                    </h5>
                    <p className="mb-0 bold font__size--14 text__14-1024 color__black-2 opacity__5">
                      Email
                    </p>
                  </div>
                  <div className="col-6 col-xl-5 mb-4 mt-md-5">
                    <img src="./../images/uikuyy.png" alt="" />
                    <h5 className="bold font__size--24 text__24-1024 text__24-sm text__24-xs mt-4 color__black-2 mb-0">
                      +91 979 939 9555
                    </h5>
                    <p className="mb-0 bold font__size--14 text__14-1024 color__black-2 opacity__5">
                      Phone
                    </p>
                  </div>
                  <div className="col-6 col-xl-5 mb-4 mt-md-5">
                    <img src="./../images/bvnvbnvb.png" alt="" />
                    <h5 className="bold font__size--24 text__24-1024 text__24-sm text__24-xs mt-4 color__black-2 mb-0">
                      cryptoxy.in
                    </h5>
                    <p className="mb-0 bold font__size--14 text__14-1024 color__black-2 opacity__5">
                      Website
                    </p>
                  </div>
                  <div className="col-6 col-xl-5 mb-4 mt-md-5">
                    <img src="./../images/tyutuyt.png" alt="" />
                    <h5 className="bold font__size--24 text__24-1024 text__24-sm text__24-xs mt-4 color__black-2 mb-0">
                      Jaipur, Rajasthan
                    </h5>
                    <p className="mb-0 bold font__size--14 text__14-1024 color__black-2 opacity__5">
                      Location
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </Fragment>
  );
};

export default Contact;
