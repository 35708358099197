import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";

const CardBlog = (props) => {
  return (
    <Fragment>
      <div className="wrapper__card-blog">
        <img src={props.data.img} className="img" alt="" />

        <NavLink to={props.data.link} className="bold font__size--20 text__20-1024 my-3 color__black d-inline-block w-100 lh-2">
          {props.data.title}
        </NavLink>
        <p className="medium font__size--16 text__16-1024 text__16-md lh-2 opacity__5">
          {props.data.desc}
        </p>
        <div className="profile">
          <img src={props.data.profile} className="img" alt="" />
          <div className="ml-3">
            <h5 className="bold font__size--14 text__14-1024 mb-1">{props.data.created}</h5>
            <p className="medium font__size--12 text__12-1024 opacity__5 mb-0">
              {props.data.job}
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CardBlog;
