import React, { Fragment, useState, useEffect } from "react";
import $ from "jquery";
import { NavLink } from "react-router-dom";

import Navbar from "../../component/navbar/Navbar";
import Footer from "../../component/footer/Footer";

import CardWork from "../../component/card/CardWork";

const Work = () => {
  const WorkList = [
    {
      img: "./../images/MOCKUP.png",
      title: "Real Estate Agency Web",
      desc: "Varius amet, integer tellus non eget viverra. Ultrices tellus donec gravida id sed senectus dolor cursus sed. ",
      class: "",
      link: "/work/detail",
    },
    {
      img: "./../images/MOCKUP (1).png",
      title: "Property Agent Website",
      desc: "Varius amet, integer tellus non eget viverra. Ultrices tellus donec gravida id sed senectus dolor cursus sed. ",
      class: "",
      link: "/work/detail",
    },
    {
      img: "./../images/MOCKUP (2).png",
      title: "Movie Apps Mobile",
      desc: "Varius amet, integer tellus non eget viverra. Ultrices tellus donec gravida id sed senectus dolor cursus sed. ",
      class: "",
      link: "/work/detail",
    },
    {
      img: "./../images/MOCKUP (3).png",
      title: "Finance App Mobile",
      desc: "Varius amet, integer tellus non eget viverra. Ultrices tellus donec gravida id sed senectus dolor cursus sed. ",
      class: "",
      link: "/work/detail",
    },
    {
      img: "./../images/lilil.png",
      title: "NFT Apps Mobile",
      desc: "Varius amet, integer tellus non eget viverra. Ultrices tellus donec gravida id sed senectus dolor cursus sed. ",
      class: "end",
      link: "/work/detail",
    },
    {
      img: "./../images/iouui.png",
      title: "Personal Portfolio Website",
      desc: "Varius amet, integer tellus non eget viverra. Ultrices tellus donec gravida id sed senectus dolor cursus sed. ",
      class: "",
      link: "/work/detail",
    },
  ];

  useEffect(() => {
    $("a[href^='#click-']").on("click", function (e) {
      e.preventDefault();
      $("html, body").animate(
        {
          scrollTop: $($(this).attr("href")).offset().top,
        },
        1000
      );
    });
  }, []);
  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="position-relative bg__oldgreen">
          <Navbar />
          <div className="pt__100">
            <section className="section__head work position-relative">
              <img src="./../images/PATTERN.png" className="path__1" alt="" />
              <div className="container position-relative z-2">
                <div className="text-center">
                  <h1 className="bold font__size--55 text__50-1024 text__50-sm text__50-xs color__white mb-0">
                    See the various kinds of best{" "}
                    <br className="d-none d-md-block" /> projects we have
                    completed
                  </h1>
                  <p className="medium font__size--16 text__16-1024 text__16-md color__white opacity__5 my-4 lh-2">
                    Varius amet, integer tellus non eget viverra. Ultrices
                    tellus donec gravida id <br className="d-none d-md-block" />{" "}
                    sed senectus dolor cursus sed. Ullamcorper tellus ac cras
                    nec, pretium <br className="d-none d-md-block" /> laoreet
                    duis.{" "}
                  </p>

                  <a
                    href="#click-to"
                    className="bold font__size--14 text__14-1024 btn btn__green color__oldgreen shadow btn__original"
                  >
                    Discover More
                  </a>
                </div>
              </div>
            </section>
          </div>
        </div>
        <section>
          <div className="container">
            <div className="wrapper__card-work position-relative" id="click-to">
              <div className="row">
                <div className="col-md-6 mb-4 my-md-auto">
                  <div className="wrap">
                    <img src="./../images/iPhone 13 Pro.png" alt="" />
                  </div>
                </div>
                <div className="col-md-6 my-auto">
                  <h4 className="bold font__size--45 text__45-1024 text__45-md mb-4">
                    E-commerce <br /> mobile app
                  </h4>
                  <p className="medium font__size--16 text__16-1024 text__16-md opacity__5 lh-2 mb-4">
                    Nec non, et sed semper suspendisse. Sapien, ridiculus
                    euismod varius faucibus feugiat et dignissim porta id.
                    Facilisi neque nec id nunc massa. Nisl nibh faucibus nunc
                    vel.
                  </p>
                  <NavLink to="/work/detail" className="color__oldgreen">
                    <div className="d-flex align-items-center">
                      <span className="bold font__size--16 text__16-1024 text__16-md">
                        See Detail Project
                      </span>
                      <img
                        src="./../images/ARROW.png"
                        className="ml-3"
                        alt=""
                      />
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10 col-xl-8">
                <div className="row justify-content-between">
                  {WorkList.map((obj, i) => {
                    return (
                      <div className="col-md-6 col-lg-5 mb-4 mb-md-0">
                        <div className={i % 2 != 0 ? "mt__4 sm-0" : ""}>
                          <CardWork data={obj} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </Fragment>
  );
};

export default Work;
