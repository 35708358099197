import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";

const OpenJob = (props) => {
  return (
    <Fragment>
      <div className="wrapper__card-job bg__white mb-4">
        <div className="d-sm-flex justify-content-between align-items-center">
          <div className="mb-4 mb-sm-0">
            <h5 className="bold font__size--24 text__24-1024 text__24-sm text__24-xs color__black-2">
              {props.data.position}
            </h5>
            <p className="medium font__size--16 text__16-1024 text__16-md mb-0 opacity__5 color__black-2">
              {props.data.type}
            </p>
          </div>
          <NavLink to={props.data.link}
            className="bold font__size--14 text__14-1024 btn btn__outlined--oldgreen color__oldgreen shadow btn__original no__opacity shadow ml-sm-3 h__green"
          >
            Apply
          </NavLink>
        </div>
      </div>
    </Fragment>
  );
};

export default OpenJob;
